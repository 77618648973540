import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputGroup from "react-bootstrap/InputGroup";
import { Link } from "react-router-dom";
import {
  Tabs,
  Tab,
  TabContainer,
  TabContent,
  Row,
  Col,
  Nav,
} from "react-bootstrap";

import Datetime from "react-datetime";

import timezones from "timezones-list";
import {
  Full_session_singleValid,
  session_recurringValid,
  session_singleValid,
} from "../../lib/validation";
import { isEmpty } from "../../helper/helper";
import moment from "moment";
import { toastAlert } from "../../utils/toast";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";

const scheduleForm = {
  Day: "",
  session_date: "",
  recurringLimt: "0",
  NoOfSession: "",
  timezone: "",
  completed_Status: 0,
};

function Webinarschedulefullconfig(props) {
  const { schudleform, setScheduleForm, selectedSession, setSelectedSessions } =
    props;

  const options = [];
  timezones.forEach(function (value, key) {
    let obj = { name: value.label, value: value.tzCode };
    options.push(obj);
  });

  let { webinar_sessionType, webinar_sessions } = schudleform;

  useEffect(() => { }, [webinar_sessionType]);
  console.log(webinar_sessions, "webinar_sessions-----111111111");

  const [sessionstartDate, setSessionStartDate] = useState(new Date());

  const [schedule_edit_index, setSchedule_Edit_index] = useState("");

  const [sessiondate, setSessionDate] = useState();

  const [Currentsession, setCurrentSession] = useState(scheduleForm);
  const [error, setError] = useState();

  const { Day, session_date, timezone, recurringLimt, NoOfSession } =
    Currentsession;

  const [showschedulesecTab1, setShowschedulesecTab1] = useState(true);
  const [showschedulesecTab2, setShowschedulesecTab2] = useState(false);
  const [showschedulesecTab3, setShowschedulesecTab3] = useState(false);
  const [isEdit, setIsEdit] = useState(false);


    useEffect(()=>{
      if (webinar_sessions?.[0]?.hasOwnProperty("sessionType")) {
        setIsEdit(true);
      }
      else {
        setIsEdit(false);
      }
    },[webinar_sessions])
  const toggleschedulesecTab1 = () => {
    setShowschedulesecTab1(true);
    setShowschedulesecTab2(false);
    setShowschedulesecTab3(false);
  };

  const toggleschedulesecTab2 = () => {
    setShowschedulesecTab1(false);
    setShowschedulesecTab2(true);
    setShowschedulesecTab3(false);
  };

  const toggleschedulesecTab3 = () => {
    setShowschedulesecTab1(false);
    setShowschedulesecTab2(false);
    setShowschedulesecTab3(true);
  };

  const sessionTypeHandler = (val) => {
      let value = val == "single" ? 0 : val == "multiple" ? 1 : 2;
      setSelectedSessions(value);
      webinar_sessions.splice(0, webinar_sessions.length);
      setScheduleForm({ ...schudleform, webinar_sessionType: value });
      // webinar_sessionType = 3    
  };

  console.log(webinar_sessionType, "webinar_sessionType");

  const session_timeHandler = async (e) => {
    console.log(e, "session_timeHandler");
    setSessionStartDate(new Date(e._d));
    setCurrentSession({ ...Currentsession, session_date: new Date(e._d) });
  };

  console.log(Currentsession, "Currentsession");

  function getNextDayByName(dayName, session_date, Day, timezone) {
    console.log(dayName, session_date, "dayName, session_date");

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const targetDayIndex = daysOfWeek.indexOf(dayName);

    console.log(targetDayIndex, "targetDayIndex");

    // moment().tz().format()

    // if (targetDayIndex === -1) {
    //     toastAlert(error, "Invalid day name");
    // }
    let currentDate;
    currentDate = new Date(session_date);

    const currentDayIndex = currentDate.getDay();
    let daysUntilNext = targetDayIndex - currentDayIndex;

    console.log(daysUntilNext, "daysUntilNext----11111");

    const currentTime = new Date();
    console.log(
      currentDate > currentTime,
      "currentDate",
      currentTime,
      currentDate
    );

    let nextDate;

    if (currentTime < currentDate) {
      nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + 0);
    } else if (dayName == "All") {
      console.log("currentDate 1111");
      nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + 1);
    } else {
      const currentDayIndex = currentDate.getDay();
      let daysUntilNext = targetDayIndex - currentDayIndex;

      if (daysUntilNext <= 0) {
        daysUntilNext += 7;
      }
      console.log(daysUntilNext, "currentDate");
      console.log("currentDate");
      nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + daysUntilNext);
    }

    // const presentTime = timeconverter.tz(timeZone).format()

    // const convertedTime = timeconverter(nextDate).tz(timeZone).format()

    // console.log(presentTime < convertedTime, "ttttt", presentTime, convertedTime)

    //   if(presentTime  )

    // if (currentTime < currentDate) {

    //     nextDate = new Date(currentDate);
    //     nextDate.setDate(currentDate.getDate() + 0);

    // }else if (dayName == 'All') {
    //     console.log('currentDate 1111')
    //      nextDate = new Date(currentDate);
    //     nextDate.setDate(currentDate.getDate() + 1);
    // } else {

    //     const currentDayIndex = currentDate.getDay();
    //     let daysUntilNext = targetDayIndex - currentDayIndex;

    //     if (daysUntilNext <= 0) {
    //         daysUntilNext += 7;
    //     }
    //     console.log(daysUntilNext, 'currentDate')
    //     console.log('currentDate')
    //     nextDate = new Date(currentDate);
    //     nextDate.setDate(currentDate.getDate() + daysUntilNext);
    // }

    console.log(nextDate, "nextDate");
    return nextDate;

    if (daysUntilNext <= 0) {
      daysUntilNext += 7;
    }
    console.log(daysUntilNext, "currentDate");
    console.log("currentDate");
    nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + daysUntilNext);
  }

  const schStartMeeting = (currentSessionDate) => {
    let currSessionDate = new Date(currentSessionDate);
    const milliseconds = currSessionDate.getTime();

    const CURRDATE = Date.now();

    let response = false;
    response = milliseconds > CURRDATE ? true : false;

    console.log(response, "CURRDATE");

    return response;
  };

  const sessionHandler = async (e) => {
    const data = moment(e._d).format("LLL");
    console.log(new Date(e._d), "new Date(e._d)");
    setSessionStartDate(new Date(e._d));
    setCurrentSession({ ...Currentsession, session_date: new Date(e._d) });
  };

  const HandleZone = (e) => {
    const { value } = e.target;
    setCurrentSession({ ...Currentsession, timezone: value });
  };

  const addSession = async () => {
    console.log(
      selectedSession,
      webinar_sessionType,
      recurringLimt,
      Day,
      timezone,
      "setAddedSession"
    );
    if (selectedSession == 0 && webinar_sessions.length > 0) {
      toastAlert("warning", "Single session contains one data");
      return false;
    }
    var dataChack;
    if (selectedSession == 2) {
      var datareccrring = getNextDayByName(
        Day,
        sessionstartDate,
        Day,
        timezone
      );

      dataChack = schStartMeeting(datareccrring);
    } else {
      dataChack = schStartMeeting(sessionstartDate);
    }

    console.log(datareccrring, "datareccrring");

    if (!dataChack) {
      toastAlert("warning", "The session date has expired");
      return false;
    }

    let dat;

    if (selectedSession == 2) {
      Currentsession.session_date = datareccrring;
    }

    if (selectedSession != "2") {
      dat = await Full_session_singleValid({
        Currentsession,
        webinar_sessions,
        edit: schedule_edit_index,
      });
    } else {
      dat = await session_recurringValid({
        ...Currentsession,
      });
    }
    console.log(dat, "addSession");
    if (isEmpty(dat)) {
      setError({});

      console.log(Currentsession, "Currentsession111111");

      const convertToUTC = (dateString) => new Date(dateString).toISOString();

      const normalizedCurrentSessionDate = selectedSession == 2 && convertToUTC(
        Currentsession.session_date
      );

      const exist_data =
        selectedSession == 2 &&
        webinar_sessions.find(
          (val) =>
            convertToUTC(val.session_date) === normalizedCurrentSessionDate
        );

      console.log(exist_data, "exist_data");
      if (exist_data) {
        toastAlert("error", "Date already submitted");
        return false;
      }

      const timezone111 = timezone;

      if (!isEmpty(schedule_edit_index)) {
        webinar_sessions.splice(schedule_edit_index, 1, Currentsession);
      } else {
        webinar_sessions.push(Currentsession);
      }

      setCurrentSession({ ...scheduleForm, timezone: timezone111 });

      setSessionStartDate(new Date());

      setSchedule_Edit_index("");

    } else {
      setError(dat);
    }
  };

  console.log(webinar_sessions, "webinar_sessions");

  const EditSession = (type, val, i) => {
    console.log(type, val, i, "type, val, i");

    if (type) {
      const newSessions = [...webinar_sessions];

      console.log(newSessions[i], "newSessions-11111");
      webinar_sessions.splice(0, webinar_sessions.length);

      const dataChack = schStartMeeting(newSessions[i].session_date);

      // if (!dataChack) {
      //   toastAlert("warning", "You can't Edit this session!,The session date has expired");
      //   return false
      // }

      // setSessionStartDate();
      // setCurrentSession();
      setSessionStartDate(new Date(newSessions[i].session_date));

      // setCurrentSession({
      //   ...Currentsession,
      //   sessionid: newSessions[i].sessionid,
      //   sessionstartDate: newSessions[i].session_date,
      //   timeZone: newSessions[i].timezone,
      // });

      setCurrentSession({
        ...Currentsession,
        // sessionType: ,
        session_date: newSessions[i].session_date,
        timezone: newSessions[i].timezone,
        // sessionid: newSessions[i].sessionid,
        Day: newSessions[i].Day,
        recurringLimt: newSessions[i].recurringLimt,
        NoOfSession: newSessions[i].NoOfSession,
        completed_Status: newSessions[i].completed_Status
          ? newSessions[i].completed_Status
          : 0,
      });

      newSessions.splice(i, 1);
      console.log(newSessions, "newSessions----111111111");
      webinar_sessions.push(...newSessions);
    } else {
      const newSessions = [...webinar_sessions];
      webinar_sessions.splice(0, webinar_sessions.length);

      const dataChack = schStartMeeting(newSessions[i].session_date);

      if (!dataChack) {
        toastAlert(
          "warning",
          "You can't edit this session!,The session date has expired"
        );
        return false;
      }

      setSessionStartDate();
      setCurrentSession();
      setSessionStartDate(new Date(newSessions[i].session_date));

      setCurrentSession({
        ...Currentsession,
        sessionid: newSessions[i].sessionid,
        sessionstartDate: newSessions[i].session_date,
        session_date: newSessions[i].session_date,
        timezone: newSessions[i].timezone,
        completed_Status: newSessions[i].completed_Status
          ? newSessions[i].completed_Status
          : 0,
      });

      newSessions.splice(i, 1);
      webinar_sessions.push(...newSessions);
      setSchedule_Edit_index(i);
    }
  };

  console.log(Currentsession, "webinar_sessionswebinar_sessions");

  const SearchHandler = async (e) => {
    console.log(e, "SearchHandler");
    setCurrentSession({
      ...Currentsession,
      timezone: e,
    });
  };

  console.log(Currentsession, "Currentsession,");
  const yesterday = moment().subtract(1, "day");
  const disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };

  const DeleteSession = async (type, val, i) => {
    webinar_sessions.splice(i,1);
  };

  console.log(session_date, "sessionstartDate------------1111111111111");
  return (
    <>
      <div>
        <div className="time-mangement">
          <div
            className={`time-schedule ${selectedSession === 0 ? "box-shadow" : ""
              }`}
          // onClick={() => sessionTypeHandler('single')}
          >
            <div class="form-check check-select">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="Webinar-radio1"
                value={0}
                checked={selectedSession === 0}
                onChange={() => sessionTypeHandler("single")}
                disabled={isEdit}
              />
              <label class="form-check-label" for="Webinar-radio1">
                One single session
                <p>
                  The registration box will display one (or many) scheduled
                  events, and your users will register to only one of them at a
                  time
                </p>
              </label>
            </div>
          </div>

          <div
            className={`time-schedule ${selectedSession === 1 ? "box-shadow" : ""
              }`}
          // onClick={() => sessionTypeHandler('multiple')}
          >
            <div class="form-check check-select">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="Webinar-radio2"
                value={1}
                checked={selectedSession === 1}
                onChange={() => sessionTypeHandler("multiple")}
                disabled={isEdit}
              />
              <label class="form-check-label" for="Webinar-radio2">
                Multiple sessions
                <p>
                  The registration box will display multiple scheduled events,
                  and your users will register to all of them from a single sign
                  up
                </p>
              </label>
            </div>
          </div>

          <div
            className={`time-schedule ${selectedSession === 2 ? "box-shadow" : ""
              }`}
          >
            <div class="form-check check-select">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="Webinar-radio3"
                value={2}
                checked={selectedSession === 2}
                onChange={() => sessionTypeHandler("recurring")}
                disabled={isEdit}
              />
              <label class="form-check-label" for="Webinar-radio3">
                Recurring series of sessions
                <p>
                  The registration box will display series of recurring daily or
                  weekly events, and your users will register to the entire
                  series for as long as it lasts.
                </p>
              </label>
            </div>
          </div>
        </div>

        {selectedSession === 2 ? (
          <div className="collapse show ">
            <Row className="schedule-sec newSission-bg align-items-center">
              <Col lg="2" xxl="1" xl="2" md="2" sm="2" className="mb-2">
                {" "}
                <p>New Session</p>
              </Col>
              <Col lg="2" xxl="1" xl="2" md="2" sm="2" className="mb-2">
                <div className="onbtn"> Every </div>
              </Col>
              <Col lg="4" xxl="2" xl="3" md="3" sm="4" className="mb-2">
              <div className="error_input">
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) =>
                    setCurrentSession({
                      ...Currentsession,
                      Day: e.target.value,
                    })
                  }
                  value={Day}
                >
                  <option>Day</option>
                  <hr></hr>
                  <option value="All">All</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>

                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>

                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>

                  <option value="Sunday">Sunday</option>
                </Form.Select>
                <div className="error_msg">
                {error && error.Day && (
                  <span style={{ color: "red" }}>{error.Day}</span>
                )}
                </div>
                </div>
              </Col>

              {/* <Col lg="2">
                {" "}
                <div>
                  <InputGroup className="">
                    <InputGroup.Text id="basic-addon1" className="img-position">
                      {" "}
                      <img
                        src={require("../../assets/images/calender.png")}
                        className="img-fluid cal-img"
                      />{" "}
                    </InputGroup.Text>
                    <Datetime
                      value={
                        new Date(startDate)
                          ? new Date(startDate)
                          : ""
                      }
                      // initialValue={sesseionDate}
                      input={true}
                      dateFormat={false}
                      timeFormat="hh:mm A"
                      onChange={session_timeHandler}
                    // isValidDate={disablePastDt}
                    />
                  </InputGroup>
                </div>
              </Col> */}
              <Col lg="4" xxl="2" xl="3" md="3" sm="4" className="mb-2">
                <div className="error_input">
                  <InputGroup className="">
                    <InputGroup.Text id="basic-addon1" className="img-position">
                      <img
                        src={require("../../assets/images/time.png")}
                        className="img-fluid cal-img"
                      />{" "}
                    </InputGroup.Text>

                    {session_date != "" && (

                      <Datetime
                        initialValue={sessionstartDate}
                        placeholder={"v"}
                        // initialValue={sesseionDate}
                        input={true}
                        dateFormat={false}
                        timeFormat="hh:mm A"
                        onChange={session_timeHandler}
                        isValidDate={disablePastDt}
                      />

                    )}

                    {session_date == "" && (

                      <Datetime
                        placeholder={"v1"}
                        initialValue={sessionstartDate}
                        input={true}
                        dateFormat={false}
                        timeFormat="hh:mm A"
                        onChange={session_timeHandler}
                        isValidDate={disablePastDt}
                      />

                    )}
                  </InputGroup>
                  <div className="error_msg">
                    {error && error.session_date && (
                      <span style={{ color: "red" }}>{error.session_date}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg="4" xxl="2" xl="3" md="3" sm="4" className="mb-2">
                <div>
                  {" "}
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) =>
                      setCurrentSession({
                        ...Currentsession,
                        recurringLimt: e.target.value,
                      })
                    }
                    value={recurringLimt}
                  >
                    <option value="0">Limited</option>
                    <option value="1">Unlimited</option>
                  </Form.Select>
                </div>
              </Col>

              {recurringLimt == "0" && (
                <Col lg="2" xxl="1" xl="2" md="2" sm="2" className="mb-2">
                  {" "}
                  <div className="error_input">
                    <InputGroup className="limit_sec">
                      <Form.Control
                        placeholder="Limit"
                        id="limit"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) =>
                          setCurrentSession({
                            ...Currentsession,
                            NoOfSession: e.target.value,
                          })
                        }
                        value={NoOfSession}
                      />
                    </InputGroup>
                    <div className="error_msg">
                      {error && error.NoOfSession && (
                        <span style={{ color: "red" }}>
                          {error.NoOfSession}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
              )}
              <Col lg="4" xxl="2" xl="3" md="3" sm="4" className="mb-2">
                {" "}
                <div className="error_input">
                  <SelectSearch
                    aria-label="Choose your Timezone"
                    search={true}
                    onChange={(e) => SearchHandler(e)}
                    options={options}
                    name="Timezone"
                    placeholder="Choose your Timezone"
                    value={timezone}
                  />
                  <div className="error_msg">
                    {error && error.timezone && (
                      <span style={{ color: "red" }}>{error.timezone}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg="2" xxl="1" xl="2" md="2" sm="2" className="mb-2">
                {" "}
                <Link className="newsission-addbtn" onClick={addSession}>
                  Add
                </Link>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="collapse show">
            <div className="newSission-bg">
              <div className="newSission">
                <p>New Session</p>
                <Link className="onbtn">On</Link>
                <div className="error_input">
                  <InputGroup className="">
                    <InputGroup.Text id="basic-addon1" className="img-position">
                      {" "}
                      <img
                        src={require("../../assets/images/calender.png")}
                        className="img-fluid cal-img"
                      />{" "}
                      {session_date == "" && (
                        <Datetime
                          initialValue={new Date()}
                          timeFormat={false}
                          onChange={sessionHandler}
                          isValidDate={disablePastDt}
                          inputProps={
                            selectedSession == 0 &&
                            webinar_sessions.length == 1 && {
                              style: { cursor: "not-allowed" },
                              disabled: true,
                            }
                          }
                        />
                      )}
                      {session_date != "" && (
                        <Datetime
                          initialValue={sessionstartDate}
                          timeFormat={false}
                          onChange={sessionHandler}
                          // dateFormat="DD-MM-YY"
                          isValidDate={disablePastDt}
                        />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                  <div className="error_msg">
                    {error && error.session_date && (
                      <span style={{ color: "red" }}>{error.session_date}</span>
                    )}
                  </div>
                </div>
                <div className="error_input">
                  <InputGroup className="">
                    <InputGroup.Text id="basic-addon1" className="img-position">
                      <img
                        src={require("../../assets/images/time.png")}
                        className="img-fluid cal-img"
                      />{" "}
                    </InputGroup.Text>
                    <Datetime
                      value={sessionstartDate}
                      dateFormat={false}
                      timeFormat="hh:mm A"
                      onChange={session_timeHandler}
                      inputProps={{
                        placeholder: "Choose time",
                        style:
                          selectedSession == 0 && webinar_sessions.length == 1
                            ? { cursor: "not-allowed" }
                            : {},
                        disabled: selectedSession == 0 && webinar_sessions.length == 1,
                      }}
                    />
                  </InputGroup>
                  <div className="error_msg">
                    {error && error.sessionTime && (
                      <span style={{ color: "red" }}>{error.sessionTime}</span>
                    )}
                  </div>
                </div>{" "}
                <div className="error_input">
                  <SelectSearch
                    aria-label="Choose your Timezone"
                    search={true}
                    onChange={(e) => SearchHandler(e)}
                    options={options}
                    name="Timezone"
                    placeholder="Choose your Timezone"
                    value={timezone}
                  />
                  <div className="error_msg">
                    {error && error.timezone && (
                      <span style={{ color: "red" }}>{error.timezone}</span>
                    )}
                  </div>
                </div>
                <Link className="newsission-addbtn" onClick={addSession}>
                  Add
                </Link>
              </div>
            </div>
          </div>
        )}

        {webinar_sessions && webinar_sessions.length > 0
          ? webinar_sessions.map((list, i) => (
            <>
              {selectedSession == "2" ? (
                <div className="collapse show ">
                  <Row className="schedule-sec newSission-bg align-items-center hover-notallow">
                    <Col lg="2" xxl="1" xl="2" md="2" sm="2" className="mb-2">
                      {" "}
                      <p>{i + 1} Session</p>
                    </Col>
                    <Col lg="2" xxl="1" xl="2" md="2" sm="2" className="mb-2">
                      <div className="onbtn"> Every</div>
                    </Col>
                    <Col lg="4" xxl="2" xl="3" md="3" sm="4" className="mb-2">
                      {" "}
                      <div>
                        <InputGroup className="">
                          <InputGroup.Text
                            id="basic-addon1"
                            className="img-position"
                          >
                            {" "}
                            <img
                              src={require("../../assets/images/calender.png")}
                              className="img-fluid cal-img"
                            />{" "}
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Select time"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="date_time"
                            value={list.Day}
                          />
                        </InputGroup>
                      </div>
                    </Col>
                    <Col lg="4" xxl="2" xl="3" md="3" sm="4" className="mb-2">
                      <div>
                        <InputGroup className="">
                          <InputGroup.Text
                            id="basic-addon1"
                            className="img-position"
                          >
                            <img
                              src={require("../../assets/images/time.png")}
                              className="img-fluid cal-img"
                            />{" "}
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Select time"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="date_time"
                            value={moment(list.session_date).format(
                              "hh:mm A"
                            )}
                          />
                        </InputGroup>
                      </div>
                    </Col>
                    <Col lg="4" xxl="2" xl="3" md="3" sm="4" className="mb-2">
                      <div>
                        {" "}
                        <Form.Select aria-label="Default select example">
                          {list.recurringLimt == "0" ? (
                            <option>Limited</option>
                          ) : (
                            <option>Unlimited</option>
                          )}
                        </Form.Select>
                      </div>
                    </Col>

                    {list.recurringLimt == "0" ? (
                      <Col
                        lg="2"
                        xxl="1"
                        xl="2"
                        md="2"
                        sm="2"
                        className="mb-2"
                      >
                        {" "}
                        <div>
                          <InputGroup className="">
                            <Form.Control
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              className="date_time"
                              value={list.NoOfSession}
                            />
                          </InputGroup>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col lg="4" xxl="2" xl="3" md="3" sm="4" className="mb-2">
                      {" "}
                      <div>
                        <Form.Select aria-label="Default select example">
                          <option>{list.timezone}</option>
                        </Form.Select>
                      </div>
                    </Col>

                    {list.completed_Status == 0 && (
                      <Col
                        lg="2"
                        xxl="1"
                        xl="2"
                        md="2"
                        sm="2"
                        className="mb-2"
                      >
                        {" "}
                        <div className="edit_icon_btn">
                          <Link
                            className=" hover-allow trash-pencil"
                            onClick={() => EditSession(true, list, i)}
                          >
                            <i class="fa-solid fa-pencil"></i>
                          </Link>
                          <Link
                            className=" hover-allow trash"
                            onClick={() => EditSession(true, list, i)}
                          >
                           <i class="fa-regular fa-trash-can"></i>
                          </Link>
                        </div>
                        {/* <Link className="newsission-addbtn">Delete</Link> */}
                      </Col>
                    )}
                  </Row>
                </div>
              ) : (

                <div className="collapse show">
                  <div className="newSission-bg  hover-notallow">
                    <div className="newSission">
                      <p>New Session</p>
                      <Link className="onbtn">On</Link>
                      <InputGroup className="">
                        <InputGroup.Text
                          id="basic-addon1"
                          className="img-position"
                        >
                          {" "}
                          <img
                            src={require("../../assets/images/calender.png")}
                            className="img-fluid cal-img"
                          />{" "}
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="Select time"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={moment(list.session_date).format("LL")}
                        />
                      </InputGroup>
                      {error && error.session_date && (
                        <span style={{ color: "red" }}>
                          {error.session_date}
                        </span>
                      )}

                      <InputGroup className="">
                        <InputGroup.Text
                          id="basic-addon1"
                          className="img-position"
                        >
                          <img
                            src={require("../../assets/images/time.png")}
                            className="img-fluid cal-img"
                          />{" "}
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="Select time"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={moment(list.session_date).format("hh:mm A")}
                        />
                      </InputGroup>
                      <Form.Select aria-label="Default select example">
                        <option>{list.timezone}</option>

                        {/* <option value="IST">IST</option>
                                                                  <option value="UST">UST</option> */}
                      </Form.Select>

                      {error && error.timezone && (
                        <span style={{ color: "red" }}>{error.timezone}</span>
                      )}

                      {list.completed_Status == 0 && (
                        <Col
                          lg="2"
                          xxl="1"
                          xl="2"
                          md="2"
                          sm="2"
                          className="mb-2"
                        >
                          {" "}
                          <div className="edit_icon_btn">
                            <Link
                              className=" hover-allow trash-pencil"
                              onClick={() => EditSession(false, list, i)}
                            >
                              <i class="fa-solid fa-pencil"></i>
                            </Link>
                            <Link className=" hover-allow trash" onClick={() => EditSession(false, list, i)}>
                            <i class="fa-regular fa-trash-can"></i>
                            </Link>
                          </div>
                          {/* <Link className="newsission-addbtn">Delete</Link> */}
                        </Col>
                      )}

                      {/* <Link
                          className="newsission-addbtn hover-allow"
                          onClick={() => EditSession(true, list, i)}
                        >
                          Edit
                        </Link> */}
                    </div>
                  </div>
                </div>

              )}
            </>
          ))
          : ""}

        {/* {showschedulesecTab1 && (
          <div className="collapse show">
            <div className="newSission-bg">
              <div className="newSission">
                <p>New Session</p>
                <Link className="onbtn">On</Link>
                <InputGroup className="">
                  <InputGroup.Text id="basic-addon1" className="img-position">
                    {" "}
                    <img
                      src={require("../../assets/images/calender.png")}
                      className="img-fluid cal-img"
                    />{" "}
                  </InputGroup.Text>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="date_time"
                  />
                </InputGroup>

                <InputGroup className="">
                  <InputGroup.Text id="basic-addon1" className="img-position">
                    <img
                      src={require("../../assets/images/time.png")}
                      className="img-fluid"
                    />{" "}
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Select time"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="date_time"
                  />
                </InputGroup>
                <Form.Select aria-label="Default select example">
                  <option>Select time zone</option>
                  <option value="1">Mountain time</option>
                  <option value="2">central time</option>
                  <option value="3">London time</option>
                </Form.Select>
                <Link className="newsission-addbtn">Add</Link>
              </div>
              <div className="newSission">
                <p>1 Session</p>
                <Link className="onbtn">On</Link>
                <InputGroup className="">
                  <InputGroup.Text id="basic-addon1" className="img-position">
                    {" "}
                    <img
                      src={require("../../assets/images/calender.png")}
                      className="img-fluid cal-img"
                    />{" "}
                  </InputGroup.Text>
                  <DatePicker
                    selected={startDate}
                    // onChange={(date) => setStartDate(date)}
                    className="date_time"
                  />
                </InputGroup>

                <InputGroup className="">
                  <InputGroup.Text id="basic-addon1" className="img-position">
                    <img
                      src={require("../../assets/images/time.png")}
                      className="img-fluid"
                    />{" "}
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Select time"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="date_time"
                  />
                </InputGroup>
                <Form.Select aria-label="Default select example">
                  <option>Select time zone</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>

                <div className="edit-close-btn">
                  <Link className="">
                    <img
                      src={require("../../assets/images/registration_imgs/pencil.png")}
                      className="img-fluid"
                    />{" "}
                  </Link>
                  <Link className="">
                    {" "}
                    <img
                      src={require("../../assets/images/registration_imgs/wrong.png")}
                      className="img-fluid"
                    />{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        {showschedulesecTab2 && (
          <div className="collapse show">
            <div className="newSission-bg">
              <div className="newSission">
                <p>New Session</p>
                <Link className="onbtn">On</Link>
                <InputGroup className="">
                  <InputGroup.Text id="basic-addon1" className="img-position">
                    {" "}
                    <img
                      src={require("../../assets/images/calender.png")}
                      className="img-fluid cal-img"
                    />{" "}
                  </InputGroup.Text>
                  <DatePicker
                    selected={startDate}
                    // onChange={(date) => setStartDate(date)}
                    className="date_time"
                  />
                </InputGroup>

                <InputGroup className="">
                  <InputGroup.Text id="basic-addon1" className="img-position">
                    <img
                      src={require("../../assets/images/time.png")}
                      className="img-fluid"
                    />{" "}
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Select time"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="date_time"
                  />
                </InputGroup>
                <Form.Select aria-label="Default select example">
                  <option>Select time zone</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
                <Link className="newsission-addbtn">Add</Link>
              </div>
              <div className="newSission">
                <p>1 Session</p>
                <Link className="onbtn">On</Link>
                <InputGroup className="">
                  <InputGroup.Text id="basic-addon1" className="img-position">
                    {" "}
                    <img
                      src={require("../../assets/images/calender.png")}
                      className="img-fluid cal-img"
                    />{" "}
                  </InputGroup.Text>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="date_time"
                  />
                </InputGroup>

                <InputGroup className="">
                  <InputGroup.Text id="basic-addon1" className="img-position">
                    <img
                      src={require("../../assets/images/time.png")}
                      className="img-fluid"
                    />{" "}
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Select time"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="date_time"
                  />
                </InputGroup>
                <Form.Select aria-label="Default select example">
                  <option>Select time zone</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>

                <div className="edit-close-btn">
                  <Link className="">
                    <img
                      src={require("../../assets/images/registration_imgs/pencil.png")}
                      className="img-fluid"
                    />{" "}
                  </Link>
                  <Link className="">
                    {" "}
                    <img
                      src={require("../../assets/images/registration_imgs/wrong.png")}
                      className="img-fluid"
                    />{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        {showschedulesecTab3 && (
          <div className="collapse show newSission-bg">
            <Row className="schedule-sec align-items-center mb-3">
              <Col lg="1">
                {" "}
                <p>New Session</p>
              </Col>
              <Col lg="1">
                <div className="onbtn"> Every</div>
              </Col>
              <Col lg="2">
                {" "}
                <div>
                  <InputGroup className="">
                    <InputGroup.Text id="basic-addon1" className="img-position">
                      {" "}
                      <img
                        src={require("../../assets/images/calender.png")}
                        className="img-fluid cal-img"
                      />{" "}
                    </InputGroup.Text>
                    <DatePicker
                      selected={startDate}
                      // onChange={(date) => setStartDate(date)}
                      className="date_time"
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col lg="2">
                <div>
                  <InputGroup className="">
                    <InputGroup.Text id="basic-addon1" className="img-position">
                      <img
                        src={require("../../assets/images/time.png")}
                        className="img-fluid"
                      />{" "}
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Select time"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      className="date_time"
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col lg="2">
                <div>
                  {" "}
                  <Form.Select aria-label="Default select example">
                    <option>Limited</option>
                    <option value="1">Unlimited</option>
                  </Form.Select>
                </div>
              </Col>
              <Col lg="1">
                {" "}
                <div>
                  <InputGroup className="">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      className="date_time"
                      value="1"
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col lg="2">
                {" "}
                <div>
                  <Form.Select aria-label="Default select example">
                    <option>Select time zone</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </div>
              </Col>
              <Col lg="1">
                {" "}
                <Link className="newsission-addbtn">Add</Link>
              </Col>
            </Row>
            <Row className="schedule-sec  align-items-center  mb-3">
              <Col lg="1">
                {" "}
                <p>1 Session</p>
              </Col>
              <Col lg="1">
                <div className="onbtn"> Every</div>
              </Col>
              <Col lg="2">
                {" "}
                <div>
                  <InputGroup className="">
                    <InputGroup.Text id="basic-addon1" className="img-position">
                      {" "}
                      <img
                        src={require("../../assets/images/calender.png")}
                        className="img-fluid cal-img"
                      />{" "}
                    </InputGroup.Text>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="date_time"
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col lg="2">
                <div>
                  <InputGroup className="">
                    <InputGroup.Text id="basic-addon1" className="img-position">
                      <img
                        src={require("../../assets/images/time.png")}
                        className="img-fluid"
                      />{" "}
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Select time"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      className="date_time"
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col lg="2">
                <div>
                  {" "}
                  <Form.Select aria-label="Default select example">
                    <option>Limited</option>
                    <option value="1">Unlimited</option>
                  </Form.Select>
                </div>
              </Col>
              <Col lg="1">
                {" "}
                <div>
                  <InputGroup className="">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      className="date_time"
                      value="1"
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col lg="2">
                {" "}
                <div>
                  <Form.Select aria-label="Default select example">
                    <option>Select time zone</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </div>
              </Col>
              <Col lg="1">
                {" "}
                <div className="edit-close-btn">
                  <Link className="">
                    <img
                      src={require("../../assets/images/registration_imgs/pencil.png")}
                      className="img-fluid"
                    />{" "}
                  </Link>
                  <Link className="">
                    {" "}
                    <img
                      src={require("../../assets/images/registration_imgs/wrong.png")}
                      className="img-fluid"
                    />{" "}
                  </Link>
                </div>
              </Col>
            </Row>
            <Row className="schedule-sec  align-items-center  mb-3">
              <Col lg="1">
                {" "}
                <p>New Session</p>
              </Col>
              <Col lg="1">
                <div className="onbtn"> Every</div>
              </Col>
              <Col lg="2">
                {" "}
                <div>
                  <InputGroup className="">
                    <InputGroup.Text id="basic-addon1" className="img-position">
                      {" "}
                      <img
                        src={require("../../assets/images/calender.png")}
                        className="img-fluid cal-img"
                      />{" "}
                    </InputGroup.Text>
                    <DatePicker
                      selected={startDate}
                      // onChange={(date) => setStartDate(date)}
                      className="date_time"
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col lg="2">
                <div>
                  <InputGroup className="">
                    <InputGroup.Text id="basic-addon1" className="img-position">
                      <img
                        src={require("../../assets/images/time.png")}
                        className="img-fluid"
                      />{" "}
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Select time"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      className="date_time"
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col lg="2">
                <div>
                  {" "}
                  <Form.Select aria-label="Default select example">
                    <option>Unlimited</option>
                    <option value="1">Limited</option>
                  </Form.Select>
                </div>
              </Col>

              <Col lg="2">
                {" "}
                <div>
                  <Form.Select aria-label="Default select example">
                    <option>Select time zone</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </div>
              </Col>
              <Col lg="1">
                {" "}
                <Link className="newsission-addbtn">Add</Link>
              </Col>
            </Row>
            <Row className="schedule-sec  align-items-center  mb-3">
              <Col lg="1">
                {" "}
                <p>1 Session</p>
              </Col>
              <Col lg="1">
                <div className="onbtn"> Every</div>
              </Col>
              <Col lg="2">
                {" "}
                <div>
                  <InputGroup className="">
                    <InputGroup.Text id="basic-addon1" className="img-position">
                      {" "}
                      <img
                        src={require("../../assets/images/calender.png")}
                        className="img-fluid cal-img"
                      />{" "}
                    </InputGroup.Text>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="date_time"
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col lg="2">
                <div>
                  <InputGroup className="">
                    <InputGroup.Text id="basic-addon1" className="img-position">
                      <img
                        src={require("../../assets/images/time.png")}
                        className="img-fluid"
                      />{" "}
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Select time"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      className="date_time"
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col lg="2">
                <div>
                  {" "}
                  <Form.Select aria-label="Default select example">
                    <option>Unlimited</option>
                    <option value="1">Limited</option>
                  </Form.Select>
                </div>
              </Col>

              <Col lg="2">
                {" "}
                <div>
                  <Form.Select aria-label="Default select example">
                    <option>Select time zone</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </div>
              </Col>
              <Col lg="1">
                {" "}
                <div className="edit-close-btn">
                  <Link className="">
                    <img
                      src={require("../../assets/images/registration_imgs/pencil.png")}
                      className="img-fluid"
                    />{" "}
                  </Link>
                  <Link className="">
                    {" "}
                    <img
                      src={require("../../assets/images/registration_imgs/wrong.png")}
                      className="img-fluid"
                    />{" "}
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        )} */}
      </div>
      {/* 
      <div className="timezoneconversion-head">
        <div className="timezoneconversion">
          <h6>Allow time zone conversion</h6>
          <p>
            The Registration form will allow your visitors to convert your
            configured time zone to theirs, so it's more convenient for them.
            However, you may turn this option OFF and therefore all times will
            always be displayed in your configured time zone
          </p>
        </div>
        <div>
          <Form.Check type="switch" id="custom-switch" />
        </div>
      </div> */}
    </>
  );
}
export default Webinarschedulefullconfig;
