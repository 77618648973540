import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import Slider from "react-slick";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AOS from "aos";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Scrollbars } from "react-custom-scrollbars-2";
import Container from "react-bootstrap/esm/Container.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <span className="arrow-img"></span>
    </div>
  );
};

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <span className="arrow-img"></span>
    </div>
  );
};
export default function Home(props) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <ScrollToTopOnMount />

      <header className="page_header">
        <Navbar />
        <div className="container pt-5 pb-4">
          <div className="row">
            <div className="col-lg-12">
              <div className="home-banner-content">
                <h1>Connect face to face Anywhere in the world</h1>
                <p className="mb-2">
                  The Ultimate Live and Automated Webinar Solution
                </p>
                <p>
                  Discover Why Over 50,000 Businesses in 70+ Industries Choose
                  Us
                </p>
                <Link to="/webinar" className="primary_btn primary_btn3">
            
                  Get Started
                </Link>
              </div>
              <img
                src={require("../assets/images/banner_image.png")}
                alt="img"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </header>
      <section className="home-white-two-column">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="home-content-secondary">
                <small>Functionality</small>
                <h3>Live Chat - Public and Private</h3>
                <p>
                  Our platform provides you with versatile tools to maximize
                  attendee participation: real-time chat, interactive Q&As,
                  private messaging for secure one-on-one conversations, and
                  public chat for lively group discussions. With advanced
                  moderation, highlighted comments, and sticky announcements,
                  you have everything you need to captivate your audience from
                  start to finish!
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content-image">
                <img
                  src={require("../assets/images/easy_connect.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-white-two-column">
        <div className="container">
          <div className="row mobile-reverse">
            <div className="col-lg-6">
              <div className="content-image">
                <img
                  src={require("../assets/images/home-contact-img.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="home-content-secondary">
                <h3>Calendar and contact integration</h3>
                <p>
                  Effortlessly sync your meetings and contacts with Google
                  Calendar and Calendly. Start or join meetings directly from
                  our desktop client, ensuring you never miss a beat. Keep your
                  schedule and contacts perfectly organized and up-to-date
                </p>
                {/* <Link to="#" className="primary_btn">
                  <img
                    src={require("../assets/images/chevron-right.png")}
                    height="16"
                    width="16"
                    className="img-fluid"
                  />{" "}
                  Learn More
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-white-two-column">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="home-content-secondary">
                <h3>Share Screen</h3>
                <p>
                  As the host in a meeting, the host has the option to limit
                  screen sharing permission so that participants can share their
                  screen. To allow participants to share their screen by
                  default, host can adjust the screen sharing settings from the
                  Settings page on the web portal.
                </p>
                {/* <Link to="#" className="primary_btn">
                  <img
                    src={require("../assets/images/chevron-right.png")}
                    height="16"
                    width="16"
                    className="img-fluid"
                  />{" "}
                  Learn More
                </Link> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content-image">
                <img
                  src={require("../assets/images/report-notifications.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-grey-two-column">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="home-content-secondary">
                <h3>Live sales of products</h3>
                <p>
                  Users can go live to sell the product and upload the details
                  of their product for the sales,other attendees can attend the
                  live sale and purchase the product from the host by providing
                  the details in the chat or via call attendees can receive the
                  product to theiraddress.
                </p>
                {/* <Link to="#" className="primary_btn">
                  <img
                    src={require("../assets/images/chevron-right.png")}
                    height="16"
                    width="16"
                    className="img-fluid"
                  />{" "}
                  Learn More
                </Link> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content-image">
                <img
                  src={require("../assets/images/ban_blw.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-dark-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="home-content-secondary text-center w-100">
                <h3 className="text-center d-block w-100">
                  White Board Option
                </h3>
                <p>
                  Whiteboard allows users to collaborate with your team by
                  launching a virtual whiteboard from within a meeting. Users
                  can share their whiteboard with meeting participants and use
                  the editing tools to start visualising ideas.
                </p>
                <p>
                  When a whiteboard is shared in a meeting, there is no set
                  limit on the number of individuals who can edit it. Host can
                  set a limit of up to 30 people can edit the whiteboard
                  simultaneously. However, as the traffic increases, the number
                  of people who can edit the whiteboard decreases.
                </p>
                <img
                  src={require("../assets/images/user.png")}
                  alt="img"
                  className="img-fluid d-block mx-auto"
                />
                {/* <Link to="#" className="white_btn mt-4">
                  Learn More
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-white-two-column">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="home-content-secondary w-85">
                <h3>
                  Empowering Business of all size and sectors: A Global solution
                </h3>
                <p>
                  Our video conferencing app is tailored to support businesses
                  of every size and industry worldwide. With high-quality video
                  and audio, seamless calendar and contact integration, and
                  advanced collaboration tools, we offer a versatile solution
                  for all your communication needs. Whether you're a startup or
                  a multinational corporation, our platform enhances
                  productivity and fosters meaningful connections. Join
                  thousands of businesses globally who trust us to keep their
                  teams connected and their operations running smoothly.
                </p>
                {/* <Link to="#" className="primary_btn">
                  <img
                    src={require("../assets/images/chevron-right.png")}
                    height="16"
                    width="16"
                    className="img-fluid"
                  />{" "}
                  Learn More
                </Link> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="industry-grid">
                <div className="ig-card w-60">
                  <img
                    src={require("../assets/images/ig-icon-1.png")}
                    alt="img"
                    className="img-fluid"
                  />
                  <p>Education</p>
                </div>
                <div className="ig-card w-40">
                  <img
                    src={require("../assets/images/ig-icon-2.png")}
                    alt="img"
                    className="img-fluid"
                  />
                  <p>Financial Services</p>
                </div>
                <div className="ig-card  w-40">
                  <img
                    src={require("../assets/images/ig-icon-3.png")}
                    alt="img"
                    className="img-fluid"
                  />
                  <p>Healthcare</p>
                </div>
                <div className="ig-card  w-60">
                  <img
                    src={require("../assets/images/ig-icon-4.png")}
                    alt="img"
                    className="img-fluid"
                  />
                  <p>Retail</p>
                </div>
                <div className="ig-card  w-60">
                  <img
                    src={require("../assets/images/ig-icon-5.png")}
                    alt="img"
                    className="img-fluid"
                  />
                  <p>Government</p>
                </div>
                <div className="ig-card  w-40">
                  <img
                    src={require("../assets/images/ig-icon-6.png")}
                    alt="img"
                    className="img-fluid"
                  />
                  <p>Manufacturing</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-dark-bg bg-image-less">
        <div className="container">
          <div className="home-content-secondary text-center text-white">
            <small>Benefit</small>
            <h3>Enhance Your Virtual Interactions</h3>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="content-image">
                <img
                  src={require("../assets/images/mobile-screen1.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="vi-content-right">
                <Scrollbars
                  style={{ width: "100%", height: 600 }}
                  className="verticalScroll"
                >
                  <div className="vi-card">
                    <div className="vi-card-text">
                      <h5>Spotlight Your Attendees</h5>
                      <p>
                        Give your audience a voice! Effortlessly bring any
                        attendee onto your virtual stage during your
                        presentation. With just a click, your invitee can speak,
                        share their screen, and broadcast their webcam, creating
                        an interactive and engaging experience. When they're
                        done, a simple click returns them to attendee-only mode,
                        ensuring a smooth transition and continuous flow of your
                        event.
                      </p>
                    </div>
                  </div>
                  <div className="vi-card">
                    <div className="vi-card-text">
                      <h5>Intelligent Noise Cancellation:</h5>
                      <p>
                        Enhance your audio quality with noise suppression and
                        echo cancellation, ensuring clear, professional sound.
                        Easily adjust or disable filters for full-range audio
                        when needed, giving you complete control over your sound
                        environment.
                      </p>
                    </div>
                  </div>
                  <div className="vi-card">
                    <div className="vi-card-text">
                      <h5>Social Media Live:</h5>
                      <p>
                        Stream meetings and webinars live on YouTube, WhatsApp,
                        Facebook, and Instagram, allowing viewers to watch and
                        comment in real-time, despite a slight delay and limited
                        direct interaction.
                      </p>
                    </div>
                  </div>
                  <div className="vi-card">
                    <div className="vi-card-text">
                      <h5>Works on any device</h5>
                      <p>
                        Our platform ensures a seamless experience across all
                        devices, whether you're using a desktop, laptop, tablet,
                        or smartphone. Enjoy full functionality and flexibility,
                        allowing you to connect and collaborate from anywhere,
                        at any time.
                      </p>
                    </div>
                  </div>
                  <div className="vi-card">
                    <div className="vi-card-text">
                      <h5>Extensive Polls & Survey</h5>
                      <p>
                        Boost audience participation and gather valuable
                        insights with our robust polls and surveys feature.
                        Easily manage and apply surveys, polls, and quizzes from
                        your library to any meeting or webinar, enhancing
                        engagement and interaction.
                      </p>
                    </div>
                  </div>

                  <div className="vi-card">
                    <div className="vi-card-text">
                      <h5>Audience leaderboard</h5>
                      <p>
                        Track audience attentiveness and participation with our
                        Audience Leaderboard feature. View detailed metrics on
                        attendance, talk time, and lobby analytics, ensuring you
                        have comprehensive insights into your event's engagement
                        levels.
                      </p>
                    </div>
                  </div>

                  <div className="vi-card">
                    <div className="vi-card-text">
                      <h5>Integration of Canva</h5>
                      <p>
                        Create stunning event templates with our Canva
                        integration. Customize and group templates effortlessly,
                        upload images to showcase on event details pages, and
                        manage your designs with ease. Enhance your event's
                        visual appeal using third-party tools like Canva.
                      </p>
                    </div>
                  </div>

                  <div className="vi-card">
                    <div className="vi-card-text">
                      <h5>Virtual Background</h5>
                      <p>
                        Enhance your meeting experience with our Virtual
                        Background feature. Display any image or video as your
                        background, adding a professional or personalized touch
                        to your virtual room. Create the perfect setting for any
                        occasion with ease.
                      </p>
                    </div>
                  </div>

                  <div className="vi-card">
                    <div className="vi-card-text">
                      <h5>Notification:</h5>
                      <p>
                        Keep your schedule on track with our timely mail
                        notifications. Receive reminders before your scheduled
                        calls, ensuring you never miss an important meeting.
                      </p>
                    </div>
                  </div>
                  <div className="vi-card">
                    <div className="vi-card-text">
                      <h5>Seamless Translation</h5>
                      <p>
                        Effortlessly transcribe meetings and translate audio to
                        text in real-time. Enhance clarity, accountability, and
                        inclusivity by reviewing discussions and translating
                        content into multiple languages.
                      </p>
                    </div>
                  </div>
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home_slider">
        <Container>
          <div className="slider-container">
            <h3 className="slider_h3tag">Real People, Real Results</h3>

            <Slider {...settings}>
              <div className="slider_content">
                <Row>
                  <Col lg={8} >
                    <div className="slider_rating ">
                      <div className="slider_text">
                        <p>
                          "Since using Vaffix, our team's productivity has increased
                          by 25%. The user interface is intuitive and allows for
                          seamless collaboration, making it a perfect fit for our
                          remote team. We highly recommend Vaffix to any business
                          looking to streamline their workflow.
                        </p>
                      </div>
                      <div className="profile_rate">
                        <img
                          src={require("../assets/images/profile.png")}
                          className=" img-fluid text-center"
                        />
                        <div>
                          <p> James Screw M</p>
                          <img
                            src={require("../assets/images/star_rating.png")}
                            className=" img-fluid text-center"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <img
                      src={require("../assets/images/lock.png")}
                      className=" img-fluid text-center lock_img"
                    />
                  </Col>
                </Row>
              </div>
              <div className="slider_content">
                <Row>
                  <Col lg={8} >
                    <div className="slider_rating ">
                      <div className="slider_text">
                        <p>
                          "Since using Vaffix, our team's productivity has increased
                          by 25%. The user interface is intuitive and allows for
                          seamless collaboration, making it a perfect fit for our
                          remote team. We highly recommend Vaffix to any business
                          looking to streamline their workflow.
                        </p>
                      </div>
                      <div className="profile_rate">
                        <img
                          src={require("../assets/images/profile.png")}
                          className=" img-fluid text-center"
                        />
                        <div>
                          <p> James Screw M</p>
                          <img
                            src={require("../assets/images/star_rating.png")}
                            className=" img-fluid text-center"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <img
                      src={require("../assets/images/lock.png")}
                      className=" img-fluid text-center lock_img"
                    />
                  </Col>
                </Row>
              </div>

              <div className="slider_content">
                <Row>
                  <Col lg={8} className="slider_rating ">
                    <div className="slider_rating">
                      <div className="slider_text">
                        <p>
                          "Since using Vaffix, our team's productivity has increased
                          by 25%. The user interface is intuitive and allows for
                          seamless collaboration, making it a perfect fit for our
                          remote team. We highly recommend Vaffix to any business
                          looking to streamline their workflow.
                        </p>
                      </div>
                      <div className="profile_rate">
                        <img
                          src={require("../assets/images/profile.png")}
                          className=" img-fluid text-center"
                        />
                        <div>
                          <p> James Screw M</p>
                          <img
                            src={require("../assets/images/star_rating.png")}
                            className=" img-fluid text-center "
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} >
                    <img
                      src={require("../assets/images/lock.png")}
                      className=" img-fluid text-center lock_img"
                    />
                  </Col>
                </Row>
              </div>

            </Slider>
          </div>
        </Container>
      </section>
      <section className="home-blog">
        <div className="container">
          <div className="home-content-secondary text-center w-100 mb-md-0 mb-sm-0">
            <h3 className="text-center w-100 mb-5">
              The help you need, when you need it
            </h3>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="home-blog-card">
                <a href="#">
                  <img
                    src={require("../assets/images/blog-img-1.png")}
                    alt="img"
                    className="img-fluid"
                  />
                </a>
                <h3>
                  <a href="#">The Evolution of Video Conferencing</a>
                </h3>
                <p>
                  From its humble beginnings as a niche technology, video
                  conferencing has evolved into a vital communication tool for
                  businesses and individuals. Early systems were bulky and
                  expensive, accessible only to large corporations.
                </p>
                {/* <Link to="#" className="primary_btn">
                  <img
                    src={require("../assets/images/chevron-right.png")}
                    height="16"
                    width="16"
                    className="img-fluid"
                  />{" "}
                  Read More
                </Link> */}
              </div>
            </div>
            <div className="col-md-4">
              <div className="home-blog-card">
                <a href="#">
                  <img
                    src={require("../assets/images/blog-img-2.png")}
                    alt="img"
                    className="img-fluid"
                  />
                </a>
                <h3>
                  <a href="#">Enhancing User Experiences</a>
                </h3>
                <p>
                  Our platform is designed with the user in mind, offering an
                  intuitive interface that makes video conferencing easy. With
                  features like one-click meeting access, customizable layouts
                  and seamless screen sharing.
                </p>
                {/* <Link to="#" className="primary_btn">
                  <img
                    src={require("../assets/images/chevron-right.png")}
                    height="16"
                    width="16"
                    className="img-fluid"
                  />{" "}
                  Read More
                </Link> */}
              </div>
            </div>
            <div className="col-md-4">
              <div className="home-blog-card">
                <a href="#">
                  <img
                    src={require("../assets/images/blog-img-3.png")}
                    alt="img"
                    className="img-fluid"
                  />
                </a>
                <h3>
                  <a href="#">Building a Secure Video Conferencing Solution</a>
                </h3>
                <p>
                  Security is at the forefront of our video conferencing
                  platform. We utilize end-to-end encryption to ensure that all
                  your communications remain private and protected from
                  unauthorized access.{" "}
                </p>
                {/* <Link to="#" className="primary_btn">
                  <img
                    src={require("../assets/images/chevron-right.png")}
                    height="16"
                    width="16"
                    className="img-fluid"
                  />{" "}
                  Read More
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-section">
        <div className="container">
          <div className="home-trial-banner">
            <h3>
              Begin your journey with <span>Vaffix</span> today...
            </h3>
            <Link to="/register" className="white_btn">
              Register, It’s Free
            </Link>
          </div>
        </div>
      </section>
      <section className="home-app-download">
        <div className="container">
          <h3>
            Download and install our app on your device for the best experience
          </h3>
          <div className="download-button-group">
            <a href="#" className="app-download-button">
              <img
                src={require("../assets/images/play-store-button-icon.png")}
                alt="img"
                className="img-fluid"
              />
            </a>
            <a href="#" className="app-download-button">
              <img
                src={require("../assets/images/apple-store-button-icon.png")}
                alt="img"
                className="img-fluid"
              />
            </a>
            <a href="#" className="app-download-button">
              <img
                src={require("../assets/images/web-buuton.png")}
                alt="img"
                className="img-fluid"
              />
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
