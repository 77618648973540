import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../components/Navbar";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Innerpagenavbar from "../components/innerpage-navbar";
import Select from "react-select";
import { delete_Webinar } from "../action/user.action";
import { useDispatch, useSelector } from "react-redux";
import { encryptString } from "../helper/helper";
import {
  Search_Details,
  Webinar_Details,
} from "../redux/features/webinar_details";

import moment, { now } from "moment";

import config from "../lib/config";

import Innerfooter from "../components/inner-footer";
import TimeClockIcon from "../assets/images/time-clock.svg";
import StartIcon from "../assets/images/startIcon.svg";
import PlayIcon from "../assets/images/playIcon.svg";
import ShareIcon from "../assets/images/shareIcon.svg";

import Warning from "../assets/images/warning.svg";
import DeleteIcon from "../assets/images/deleteIcon.svg";
import EditIcon from "../assets/images/editIcon.svg";
import ShareIconToggle from "../assets/images/shareIconToggle.svg";
import InfoIcon from "../assets/images/infoIcon.svg";

import AddWebinarIcon from "../assets/images/addWebinarIcon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faShareFromSquare,
  faMagnifyingGlass,
  faListUl,
  faTh,
} from "@fortawesome/free-solid-svg-icons";

//COMPONENTS
import ConfirmationModal from "../components/common/modals/ConfirmationModal";
import { toastAlert } from "../utils/toast";
import PropagateLoader from "react-spinners/PropagateLoader";

//Filter Date- Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "../components/Pagination/Pagination";

const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999",
};

const MyWebinar = () => {
  //Date
  const [startDate, setStartDate] = useState(new Date());

  const [showContent1, setShowContent1] = useState(true);
  const [showContent2, setShowContent2] = useState(false);
  


  const toggleContent1 = () => {
    setShowContent1(true);
    setShowContent2(false);
  };

  const toggleContent2 = () => {
    setShowContent1(false);
    setShowContent2(true);
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const Copyref = useRef();

  const [MeetingDeatils, setMeetingDeatils] = useState();
  const meetings = Array.isArray(MeetingDeatils) ? MeetingDeatils : [];
  //----------------------Date Filter -----------------------------
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log("Selected Date:", date);
    setCurrentPage(1);
  };
  console.log("meetings", meetings)
  const convertTime = (sessiondate, timezone) => {
    console.log("sessiondate=======", sessiondate);
    console.log("timezone=========", timezone);

    // Parse the sessiondate assuming it's in ISO 8601 format and in UTC
    const originalDateTime = moment.utc(sessiondate).tz(timezone);
    console.log("originalDateTime=====", originalDateTime.format());

    // Format to the desired output
    const formattedDate = originalDateTime.format("MM/DD/YYYY hh:mm A");
    return formattedDate;
  };
  // Filter meetings based on the selected date
  // const filteredMeetings = selectedDate ? meetings.filter((meeting) => {
  //     const meetingDate = new Date(meeting.startTime);
  //     console.log(selectedDate,"Meeting Start Time:",meeting, meetingDate);
  //     return meetingDate.toDateString() >= selectedDate.toDateString();
  //   })
  //   : meetings;

  const filteredMeetings = selectedDate ? meetings
    .map((meeting) => {
      const filteredSessions = meeting.sessions.filter((session) => {
        return convertTime(session.startDate, session.timeZone) == convertTime(selectedDate, session.timeZone);
      });
      // Return the meeting only if it has sessions that match the date
      return { ...meeting, sessions: filteredSessions };
    })
    .filter((meeting) => meeting.sessions.length > 0) // Filter out meetings without matching sessions
    : meetings

  console.log("filteredMeetings", filteredMeetings)

  // Reset date function
  const handleReset = () => {
    setSelectedDate(null);
  };
  //----------------------Date Filter && Pagination-----------------------------
  // Paginate
  const [currentPage, setCurrentPage] = useState(1);
  const [sessionsPerPage, setSessionsPerPage] = useState(5);

  // Pagination Calculations
  const lastSessionNumber = currentPage * sessionsPerPage;
  const firstSessionIndex = lastSessionNumber - sessionsPerPage;

  const paginatedMeetings = filteredMeetings.slice(
    firstSessionIndex,
    lastSessionNumber
  );
  const totalSessionsCount = filteredMeetings.length;

  //---------------------Pagination --------------------

  const Meeting_Deatils = useSelector((state) => state.Webinar);
  console.log(Meeting_Deatils, "MeetingDeatils");

  const user_details = useSelector((state) => state.User);

  const getMeetingDetails = () => {
    setLoader(true);
    if (Meeting_Deatils) {
      setMeetingDeatils(Meeting_Deatils);
      setLoader(false);
    }
  };

  useEffect(() => {
    getMeetingDetails();
  }, [Meeting_Deatils]);

  const [initialState, setinitialState] = useState({ configure: "", type: "" });

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [delMeetingId, setDelMeetingId] = useState(null);
  const confirmationConfig = {
    title: "Delete Webinar",
    message: "Are you sure want to delete the meeting?",
  };

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const [activeModel, setActiveModel] = useState(null);
  const [activeBody, setActiveBody] = useState(1);
  const [copyValue, setCopyValue] = useState();
  const [defaulttemplate, setdefaultTemplate] = useState();

  const [loader, setLoader] = useState(false);

  // const [meeting, setMeeting] = useState({});
  const [WebinarId, setWebinarId] = useState();
  const [searchMeetingTitle, setSearchMeetingTitle] = useState("");

  const [internalMeetingID, setinternalMeetingID] = useState("");

  const [webinarFilter, setWebinarFilter] = useState("all");

  const [showPlayer, setshowPlayer] = useState(false);

  const closeShowPlayer = () => setshowPlayer(false);

  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);

  const handleShow = () => setShow(true);

  const handleShow1 = async (list, webinar) => {
    console.log(list, "createMeeting", webinar);
    let payload;
    if (list.mettingType == 0) {
      payload = {
        id: list._id,
        type: list.mettingType,
      };
    } else {
      payload = {
        id: list._id,
        type: 1,
      };
    }

    console.log(payload, "createMeeting");

    setdefaultTemplate(webinar.templateId);

    payload = JSON.stringify(payload);
    const dat = await encryptString(payload, true);
    console.log("Register page url:=", dat);
    // return
    setWebinarId(dat);
    setShow1(true);
  };

  const copyClipboard = () => {
    console.log(defaulttemplate, "defaulttemplate");
    let value;
    if (defaulttemplate) {
      value = `${config.front_URL}/enroll/${WebinarId}`;
    } else {
      value = `${config.front_URL}/attendeesRegister/${WebinarId}`;
    }
    navigator.clipboard.writeText(value);
    setCopyValue(value);
  };

  const handleBodyClick = (bodyId) => {
    setActiveBody(bodyId);
  };

  const handleModelClick = (model) => {
    setinitialState({ ...initialState, configure: model });
    setActiveModel(model);

    setShow(false);
    setshowconfig(true);
  }; //1model
  const [showconfig, setshowconfig] = useState(false);

  const [activeModel1, setActiveModel1] = useState(null);

  //Useeffect
  useEffect(() => {
    if (searchMeetingTitle !== "") {
      dispatch(
        Search_Details({ name: searchMeetingTitle, type: webinarFilter })
      );

      console.log("Search_Details", Search_Details);
    }
  }, [searchMeetingTitle]);

  useEffect(() => {
    if (webinarFilter !== "all") {
      dispatch(Webinar_Details(webinarFilter));

      console.log("Search_Details", Search_Details);
    }
  }, [webinarFilter]);

  const confighandleClose = () => setshowconfig(false);

  const confighandleShow = () => {
    if (activeModel != null) {
      setShow(false);
      setshowconfig(true);
    }
    return false;
  };

  const handleModelClick1 = async (model1) => {
    console.log(model1, "model1");

    setinitialState({ ...initialState, type: model1 });

    setActiveModel1(model1);

    const dat = await encryptString(
      JSON.stringify({ ...initialState, type: model1 }),
      true
    );

    if (initialState.configure == "express") {
      navigate(`/NewBasicSettings/${dat}`);
    } else {
      navigate(`/configSettings/config/${dat}`);
    }
  }; //2model

  console.log(activeModel, initialState, "initialState");

  const confighandleShowonfig = async () => {
    if (activeModel != null) {
      setshowconfig(false);
    }
    return false;
  };

  //Delete - vaffix meeting functions
  const deleteMeeting = (meetingID) => {
    setDelMeetingId(meetingID);
    setShowConfirmationModal(true);
  };

  const hideConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const submitDelete = async (id) => {
    setLoader(true);
    const payload = {
      id: id,
    };
    console.log("payload_Id", payload)
    const { status, result } = await delete_Webinar(payload);
    if (status) {
      dispatch(Webinar_Details());
      toastAlert("success", "Deleted successfully");
    }
    setShowConfirmationModal(false);
    setLoader(false);
  };

  const createMeeting = async (list) => {
    let payload;
    if (list.mettingType == 0) {
      payload = {
        id: list._id,
        type: list.mettingType,
      };
    } else {
      payload = {
        id: list._id,
        type: 1,
      };
    }

    console.log(payload, "createMeeting");

    payload = JSON.stringify(payload);
    const dat = await encryptString(payload, true);
    // return
    console.log(dat, "dat");
    navigate(`/last-minute/step1/${dat}`);
  };

  const schStartMeeting = (
    currentSessionDate,
    nextSessionDate,
    index,
    sessions,
    sessionLen
  ) => {
    let currSessionDate = new Date(currentSessionDate);
    let nxtSessionDate = new Date(nextSessionDate);

    const CURRDATE = Date.now();

    let response = false;
    if (sessionLen === 1) {
      response = true;
    } else {
      if (index === 0) {
        response =
          currSessionDate >= CURRDATE || nxtSessionDate >= CURRDATE
            ? true
            : false;
      } else {
        if (index === sessionLen - 1) {
          response =
            currSessionDate <= CURRDATE ||
            sessions[index - 1]?.startDate < CURRDATE;
        } else {
          response =
            (currSessionDate <= CURRDATE && nxtSessionDate >= CURRDATE) ||
              (currSessionDate <= CURRDATE &&
                nxtSessionDate >= CURRDATE &&
                sessions[index - 1]?.status === 2)
              ? true
              : false;
        }
      }
    }

    // console.log(response, 'schStartMeeting')
    return response;
  };

  const InfoHandler = async (id, type) => {
    console.log(id, "WebinarAnalytics", type);

    navigate("/WebinarAnalytics", { state: { result: id, type: type } });
  };



  const FilterHandler = async (e) => {
    console.log(e.value, "e.target.value");
    setWebinarFilter(e.value);
  };
  const options = [
    { value: "0", label: "All" },
    { value: "1", label: "Completed Meeting" },
    { value: "2", label: "In-Complete Meetings" },
    { value: "3", label: "Upcoming Meetings" },
  ];

  const [selectedMeetings, setSelectedMeetings] = useState([]);

  // const handleCheckboxChange = (e, meetingId) => {
  //   console.log("metid433", meetingId)
  //   if (e?.target?.checked) {
  //     setSelectedMeetings((prev) => [...selectedMeetings, meetingId]);
  //   }
  //   else {
  //     let arr = selectedMeetings.filter(object => object != meetingId);
  //     setSelectedMeetings(arr);
  //   }
  // };

  // const handlelabelChange = (meetingId) => {
  //   console.log("444---", meetingId)
  //   let checkMeet = selectedMeetings
  //   checkMeet = checkMeet.find(el => el == meetingId)
  //   if (checkMeet) {
  //     let arr = selectedMeetings.filter(object => object != meetingId);
  //     setSelectedMeetings(arr);
  //   }
  //   else {
  //     setSelectedMeetings((prev) => [...selectedMeetings, meetingId]);
  //   }
  // };

  const toggleMeetingSelection = (meetingId) => {
    console.log("meid457", meetingId)
    // Toggling selection based on current state
    setSelectedMeetings((prev) =>
      prev.includes(meetingId)
        ? prev.filter((id) => id !== meetingId)
        : [...prev, meetingId]
    );
  };

  const handleDeleteSelected = () => {
    console.log("select:=", selectedMeetings?.toString())
    submitDelete(selectedMeetings?.toString())
    // selectedMeetings.forEach((meetingId) => deleteMeeting(meetingId));
    // setSelectedMeetings(new Set()); // Clear selection after delete
  };

  console.log("selectedDate", selectedDate);
  const [editpresenter, setEdit_presenter] = useState(true);
  return (
    <>
      {loader && (
        <div className="loadBefore">
          <PropagateLoader
            color={"#0d2a35"}
            loading={loader}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <div className="nav_sec">
        <Header />
      </div>
      <div className="dashboard-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 ps-0">
              <Innerpagenavbar />
            </div>
            <div className="col-lg-10">
              <div className="dashbord-right">
                <div className="dashbord-right-body-1 dashboard-padding">
                  <div className="dashbord-right-header">
                    <p>My Webinars</p>
                  </div>
                  <div className="grid_Row_list">
                    <div className="grid_Row_list_child">
                      {selectedMeetings?.length > 0 &&
                        <button
                          className="registration_nav_content"
                          onClick={handleDeleteSelected}
                          disabled={selectedMeetings.size === 0}
                        >
                          <img src={DeleteIcon} alt="img" className="img-fluid" />
                        </button>
                      }
                      <InputGroup className="">
                        <Form.Control
                          placeholder="Search"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          onChange={(e) =>
                            setSearchMeetingTitle(e.target.value)
                          }
                        />
                        <InputGroup.Text id="basic-addon2">
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </InputGroup.Text>
                      </InputGroup>
                      <Select
                        id="filterSessions"
                        className="filterSessions"
                        onChange={FilterHandler}
                        options={options}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "5px",
                          width: "100%",
                          colors: {
                            ...theme.colors,
                            primary25: "#f0f2f3",
                            primary: "#001f2a",
                            neutral80: "#001f2a",
                            neutral20: "#d1d4d7",
                            neutral50: "#001f2a",
                            neutral60: "#d1d4d7",
                            neutral40: "#d1d4d7",
                            primary50: "#d1d4d7",
                          },
                        })}
                      />
                      <div className="datapick">
                        <DatePicker
                          selected={selectedDate}
                          showIcon
                          icon={
                            <svg
                              width="18"
                              height="20"
                              viewBox="0 0 18 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.168 14.0833L10.418 13.75"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.168 15.9165L10.418 16.2498"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.832 1.6665V4.99984"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12.5833 12.6665L12.25 11.9165"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12.5833 17.333L12.25 18.083"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M14.7513 11.9165L14.418 12.6665"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M14.7513 18.083L14.418 17.333"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M16 8.74967V4.99967C16 4.55765 15.8244 4.13372 15.5118 3.82116C15.1993 3.5086 14.7754 3.33301 14.3333 3.33301H2.66667C2.22464 3.33301 1.80072 3.5086 1.48816 3.82116C1.17559 4.13372 1 4.55765 1 4.99967V16.6663C1 17.1084 1.17559 17.5323 1.48816 17.8449C1.80072 18.1574 2.22464 18.333 2.66667 18.333H7.66667"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M16.582 13.75L15.832 14.0833"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M16.582 16.2498L15.832 15.9165"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M1 8.33301H16"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M5.16797 1.6665V4.99984"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M13.5 17.5C14.8807 17.5 16 16.3807 16 15C16 13.6193 14.8807 12.5 13.5 12.5C12.1193 12.5 11 13.6193 11 15C11 16.3807 12.1193 17.5 13.5 17.5Z"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          }
                          className="datapick_head"
                          isClearable
                          onChange={handleDateChange}
                          dateFormat="yyyy/MM/dd"
                          placeholderText="Select a date"
                        />
                      </div>
                      {/* <div className="icon_grid_list">
                      <Link to="#"
                            className={`primary_btn ${isDisabled ? 'disabled' : ''}`} 
                            onClick={!isDisabled ? handleQuickLink : null}
                     >
                        <img
                          src={AddWebinarIcon}
                          height="16"
                          width="16"
                          className="img-fluid"
                        />{" "}
                        Quick Meeting
                      </Link>
                    </div> */}
                    </div>

                    <div className="icon_grid_list">
                      <Link to="#" className="primary_btn" onClick={handleShow}>
                        <img
                          src={AddWebinarIcon}
                          height="16"
                          width="16"
                          className="img-fluid"
                        />{" "}
                        Add Webinar
                      </Link>
                    </div>
                  </div>

                  {showContent1 && (
                    <>
                      {paginatedMeetings?.length > 0 ? (
                        <>
                          {paginatedMeetings.map((list, index) => (
                            <>
                              <div
                                key={list._id}
                                className="Webinar-check form-check"
                              >
                                {/* <input
                                  type="checkbox"
                                  // id="Webinar-check-box"
                                  id={`Webinar-check-box-${list._id}`}
                                  className="Webinar-check-box form-check-input"
                                  // checked={!selectedMeetings}
                                  checked={selectedMeetings.includes(list._id)}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, list._id)
                                  }
                                /> */}

                                <input
                                  type="checkbox"
                                  id={`Webinar-check-box-${list._id}`}
                                  className="Webinar-check-box form-check-input"
                                  checked={selectedMeetings.includes(list._id)}
                                  onChange={() => toggleMeetingSelection(list._id)} // Reused function for checkbox
                                />

                                {/* <label

                                  onClick={() =>
                                    handlelabelChange(list._id)
                                  }
                                  htmlFor={`Webinar-check-box-${list._id}`}
                                  // for="Webinar-check-box"
                                  className="dashbord-right-body mb-3"
                                > */}

                                <label
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent default label behavior
                                    toggleMeetingSelection(list._id); // Manually handle selection
                                  }}
                                  // onClick={() => toggleMeetingSelection(list._id)} // Reused function for label click
                                  htmlFor={`Webinar-check-box-${list._id}`}
                                  className="dashbord-right-body mb-3"
                                >
                                  <div className="dashbordrightbody-header dashbordrightbody-header1">
                                    <div className="titleCount">
                                      <p>{list?.title}</p>
                                      <span className="countLabel">
                                        User Count :
                                        {list.AttendeeUser?.[0]?.TotalUserCount
                                          ? list.AttendeeUser?.[0]
                                            ?.TotalUserCount
                                          : 0}
                                      </span>
                                      {(list.configType == 0 &&
                                        list.mettingType != 0 &&
                                        list?.sessions?.length == 0) ||
                                        (list.configType == 1 &&
                                          ((list.mettingType != 0 &&
                                            list?.sessions?.length == 0 &&
                                            !list.templateId) ||
                                            (list.mettingType == 0 &&
                                              !list.templateId))) ? (
                                        <div className="incomplete-notify">
                                          <img
                                            src={Warning}
                                            alt="img"
                                            className="img-fluid"
                                          />
                                          <p>Configuration Incomplete</p>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>

                                    <div className="actionToggle">
                                      <ul>
                                        {list?.status != 2 ? (
                                          <li>
                                            <a
                                              href="#"
                                              onClick={() => (
                                                list.configType == 0
                                                  ? navigate(`/express-edit/${list._id}`)
                                                  : navigate(`/configSettings/${list._id}`)
                                              )}
                                            >
                                              <img
                                                src={EditIcon}
                                                alt="img"
                                                className="img-fluid"
                                              />
                                            </a>
                                          </li>
                                        ) : (
                                          ""
                                        )}
                                        <li>
                                          <a
                                            href="#"
                                            onClick={() =>
                                              deleteMeeting(list._id)
                                            }
                                          >
                                            <img
                                              src={DeleteIcon}
                                              alt="img"
                                              className="img-fluid"
                                            />
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="scroll-auto1">
                                    {list?.mettingType === 1 ||
                                      list?.mettingType === 2 ? (
                                      <>
                                        {list?.sessions?.map(function (
                                          item,
                                          index
                                        ) {
                                          let currentSessionDate = new Date(
                                            item?.convertedDate
                                          );
                                          let nextSessionDate = new Date(
                                            list?.sessions?.[
                                              index + 1
                                            ]?.convertedDate
                                          );
                                          console.log(item, '--------------768')
                                          let currentDate = Date.now();
                                          return (
                                            <>
                                              <div className="dashbordrightbody-body">
                                                <div className="dashbordrightbody-list1">
                                                  <div className="dashbordrightbody-list">
                                                    <div className="dashbordrightbody-content">
                                                      <img
                                                        src={TimeClockIcon}
                                                        alt="img"
                                                        className="img-fluid"
                                                      />
                                                      <p>
                                                        Session Time New:
                                                        {convertTime(
                                                          item?.startDate,
                                                          item?.timeZone
                                                        )}
                                                      </p>
                                                      <p>
                                                        TimeZone:
                                                        {item.timeZone}
                                                      </p>
                                                    </div>

                                                    <div className="dashboard_right_body_1">
                                                      {item?.status != 2 && (
                                                        <>
                                                          <OverlayTrigger
                                                            overlay={
                                                              <Tooltip id="tooltip-disabled">
                                                                Share attendee
                                                                link
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <Link
                                                              to="#"
                                                              className="primary_btn2"
                                                              onClick={() =>
                                                                handleShow1(
                                                                  item,
                                                                  list
                                                                )
                                                              }
                                                            >
                                                              <img
                                                                src={ShareIcon}
                                                                height="16"
                                                                width="16"
                                                                className="img-fluid shareIcon"
                                                              />
                                                              Share
                                                            </Link>
                                                          </OverlayTrigger>

                                                          {!schStartMeeting(
                                                            currentSessionDate,
                                                            nextSessionDate,
                                                            index,
                                                            list?.sessions,
                                                            list?.sessions
                                                              ?.length
                                                          ) && (
                                                              <OverlayTrigger
                                                                overlay={
                                                                  <Tooltip id="tooltip-disabled">
                                                                    {currentSessionDate <
                                                                      currentDate
                                                                      ? "Meeting expired"
                                                                      : "Upcomming meeting"}
                                                                  </Tooltip>
                                                                }
                                                              >
                                                                <Link
                                                                  to="#"
                                                                  className="primary_btn disabled"
                                                                >
                                                                  <img
                                                                    src={
                                                                      StartIcon
                                                                    }
                                                                    height="16"
                                                                    width="16"
                                                                    className="img-fluid"
                                                                  />
                                                                  {currentSessionDate <
                                                                    currentDate
                                                                    ? "Expired "
                                                                    : "Up Next"}
                                                                </Link>
                                                              </OverlayTrigger>
                                                            )}
                                                        </>
                                                      )}

                                                      {schStartMeeting(
                                                        currentSessionDate,
                                                        nextSessionDate,
                                                        index,
                                                        list?.sessions,
                                                        list?.sessions?.length
                                                      ) &&
                                                        item?.status !== 2 ? (
                                                        <>
                                                          <OverlayTrigger
                                                            overlay={
                                                              <Tooltip id="tooltip-disabled">
                                                                Start meeting
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <Link
                                                              to="#"
                                                              className="primary_btn"
                                                              onClick={() =>
                                                                createMeeting(
                                                                  item
                                                                )
                                                              }
                                                            >
                                                              <img
                                                                src={StartIcon}
                                                                height="16"
                                                                width="16"
                                                                className="img-fluid"
                                                              />
                                                              Start
                                                            </Link>
                                                          </OverlayTrigger>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {item?.status ===
                                                            2 && (
                                                              <>
                                                                <button
                                                                  className="primary_btn2"
                                                                  onClick={() =>
                                                                    InfoHandler(
                                                                      item._id,
                                                                      1
                                                                    )
                                                                  }
                                                                >
                                                                  <img
                                                                    src={InfoIcon}
                                                                    height="16"
                                                                    width="16"
                                                                    className="img-fluid playIcon"
                                                                  />
                                                                  Info
                                                                </button>

                                                                <button
                                                                  // to="/"
                                                                  className="primary_btn"
                                                                  onClick={() => {
                                                                    setshowPlayer(
                                                                      true
                                                                    );
                                                                    setinternalMeetingID(
                                                                      item.internalMeetingID
                                                                    );
                                                                  }}
                                                                >
                                                                  <img
                                                                    src={PlayIcon}
                                                                    height="16"
                                                                    width="16"
                                                                    className="img-fluid playIcon"
                                                                  />
                                                                  {"Play"}
                                                                </button>
                                                              </>
                                                            )}
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <>
                                        <div className="dashbordrightbody-body">
                                          <div className="dashbordrightbody-list1">
                                            <div className="dashbordrightbody-list">
                                              <div className="dashbordrightbody-content">
                                                <img
                                                  src={TimeClockIcon}
                                                  alt="img"
                                                  className="img-fluid"
                                                />
                                                <p>
                                                  Session Time:
                                                  {moment(
                                                    list?.createdAt
                                                  ).format("LLLL")}
                                                </p>
                                              </div>

                                              <div className="share-flex">
                                                {list?.sessions?.length == 0 ? (
                                                  <>
                                                    <OverlayTrigger
                                                      overlay={
                                                        <Tooltip id="tooltip-disabled">
                                                          Share attendee link
                                                        </Tooltip>
                                                      }
                                                    >
                                                      <Link
                                                        to="#"
                                                        className="primary_btn2"
                                                        onClick={() =>
                                                          handleShow1(
                                                            list,
                                                            list
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={ShareIcon}
                                                          height="16"
                                                          width="16"
                                                          className="img-fluid shareIcon"
                                                        />
                                                        Share
                                                      </Link>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                      overlay={
                                                        <Tooltip id="tooltip-disabled">
                                                          Start meeting
                                                        </Tooltip>
                                                      }
                                                    >
                                                      <Link
                                                        to="#"
                                                        className="primary_btn"
                                                        onClick={() =>
                                                          createMeeting(list)
                                                        }
                                                      >
                                                        <img
                                                          src={StartIcon}
                                                          height="16"
                                                          width="16"
                                                          className="img-fluid"
                                                        />
                                                        Start
                                                      </Link>
                                                    </OverlayTrigger>
                                                  </>
                                                ) : (
                                                  <>
                                                    {list?.sessions?.[0]
                                                      ?.status === 2 && (
                                                        <>
                                                          <button
                                                            // to="/"
                                                            className="primary_btn2"
                                                            onClick={() =>
                                                              InfoHandler(
                                                                list._id,
                                                                0
                                                              )
                                                            }
                                                          >
                                                            <img
                                                              src={InfoIcon}
                                                              height="16"
                                                              width="16"
                                                              className="img-fluid playIcon"
                                                            />
                                                            Info
                                                          </button>

                                                          <button
                                                            // to="/"
                                                            className="primary_btn"
                                                            onClick={() => {
                                                              setinternalMeetingID(
                                                                list.sessions?.[0]
                                                                  .internalMeetingID
                                                              );
                                                              setshowPlayer(true);
                                                            }}
                                                          >
                                                            <img
                                                              src={PlayIcon}
                                                              height="16"
                                                              width="16"
                                                              className="img-fluid playIcon"
                                                            />
                                                            {"Play"}
                                                          </button>
                                                        </>
                                                      )}
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </label>
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          <div className="nomeeting_img">
                            <h2 className="no-meeting">
                              No Completed Meetings
                            </h2>
                            <img
                              src={require("../assets/images/no-meeting.png")}
                              className="mx-auto d-block img-fluid"
                            />
                          </div>
                        </>
                      )}
                      <>
                        {/* Pagination */}
                        {totalSessionsCount > 0 && (
                          <>
                            {/* Pagination */}
                            <div className="pagination-flex">
                              <div>
                                <label htmlFor="itemsPerPage">
                                  Items per page:{" "}
                                </label>
                                <select
                                  id="itemsPerPage"
                                  value={sessionsPerPage}
                                  onChange={(e) =>
                                    setSessionsPerPage(Number(e.target.value))
                                  }
                                >
                                  <option value={5}>5</option>
                                  <option value={10}>10</option>
                                  <option value={20}>20</option>
                                </select>
                              </div>
                              {/* Pagination */}
                              <div className="items-per-page-selector">
                                <Pagination
                                  itemsCount={totalSessionsCount}
                                  itemsPerPage={sessionsPerPage}
                                  currentPage={currentPage}
                                  setCurrentPage={setCurrentPage}
                                  alwaysShown={false}
                                />
                                {/* Add a dropdown */}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Innerfooter />
      <div className="popup1">
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="model-head" closeButton>
            <Modal.Title className="model-title">
              Webinar configuration
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className={`model-white ${activeModel === "express" ? "active" : ""
                }`}
              onClick={() => handleModelClick("express")}
            >
              <div className="model-content">
                <img
                  src={require("../assets/images/model-img1.png")}
                  alt="img"
                  className="img-fluid"
                />
                <div>
                  <h6>Express configuration</h6>
                  <ul>
                    <li>Ready in less than 60 seconds</li>
                    <li>Simple & Quick</li>
                    <li>Based on smart defaults</li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className={`model-white ${activeModel === "full" ? "active" : ""
                }`}
              onClick={() => handleModelClick("full")}
            >
              <div className="model-content">
                <img
                  src={require("../assets/images/model-img2.png")}
                  alt="img"
                  className="img-fluid"
                />
                <div>
                  <h6>Full configuration</h6>
                  <ul>
                    <li>Takes about 10 minutes</li>
                    <li>Flexible and powerful</li>
                    <li>Based on your custom settings</li>
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div className="popup1">
        <Modal
          size="lg"
          show={showconfig}
          onHide={confighandleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="model-head" closeButton>
            <Modal.Title className="model-title">
              Webinar configuration
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className={`model-white ${activeModel1 === "Instant Meeting" ? "active" : ""
                }`}
              onClick={() => handleModelClick1("Instant Meeting")}
            >
              <div className="model-content">
                <img
                  src={require("../assets/images/model-img3.png")}
                  alt="img"
                  className="img-fluid"
                />
                <div>
                  <h6>Instant Meeting</h6>
                  <ul>
                    <li>
                      Both you and other participants are ready to live right
                      now
                    </li>
                    <li>
                      You will send the direct link to the live room to your
                      attendees
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className={`model-white ${activeModel1 === "Schedule Meeting" ? "active" : ""
                }`}
              onClick={() => handleModelClick1("Schedule Meeting")}
            >
              <div className="model-content">
                <img
                  src={require("../assets/images/model-img4.png")}
                  alt="img"
                  className="img-fluid"
                />
                <div>
                  <h6>Schedule Meeting</h6>
                  <ul>
                    <li>
                      The webinar will take place at a later time in the future
                    </li>
                    <li>
                      Your attendees will fill in a registration form to receive
                      the webinar link
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div className="popup1">
        <Modal
          size="lg"
          show={show1}
          onHide={handleClose1}
          keyboard={false}
          className="link_model"
        >
          <Modal.Header className="model-head" closeButton>
            <Modal.Title className="model-title">Your Link</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              defaultActiveKey="home"
              transition={false}
              id="noanim-tab-example"
              className="model_tab mb-3 mt-3"
            >
              <Tab
                eventKey="home"
                title={
                  <div
                    className={`dashboard_link_model ${activeBody === 1 ? "active" : ""
                      }`}
                    onClick={() => handleBodyClick(1)}
                  >
                    <div className="model_content_1">
                      <img
                        src={require("../assets/images/registration_imgs/link_img.png")}
                        alt="img"
                        className="img-fluid"
                      />
                      <h6>Attendees Link</h6>
                    </div>
                  </div>
                }
              >
                <Tabs
                  defaultActiveKey="home"
                  transition={false}
                  id="noanim-tab-example"
                  className="tab_button mb-3 mt-5"
                >
                  <Tab eventKey="home" title="Link to the registration page">
                    <p>
                      Send this link to your attendees so they may register for
                      your webinar
                    </p>
                    <div class="modal_form mb-3">
                      <label for="exampleFormControlInput1" class="form-label">
                        Registration page
                      </label>
                      <div className="modal_text_box">
                        <input
                          type="email"
                          class="form-control"
                          id="exampleFormControlInput1"
                          disabled
                          value={`${config.front_URL}/*********/****`}
                        />
                        <div
                          className="add_info_img"
                          onClick={() => copyClipboard()}
                        >
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                Copy attendee link
                              </Tooltip>
                            }
                          >
                            <a
                              href="#"
                              onClick={() =>
                                toastAlert("success", "Link copied")
                              }
                            >
                              <img
                                src={require("../assets/images/copy2.png")}
                                className="img-fluid"
                              />
                            </a>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="profile"
                    disabled={true}
                    title="Embed in your website"
                  >
                    <p>
                      Send this link to your attendees so they may register for
                      your webinar
                    </p>
                    <div class="modal_form mb-3">
                      <label for="exampleFormControlInput1" class="form-label">
                        Registration page
                      </label>
                      <div className="modal_text_box">
                        <input
                          type="email"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="https://:event.vaffix.com/register/1/ogw2kcw"
                        />
                        <div className="add_info_img">
                          <a href="#">
                            <img
                              src={require("../assets/images/registration_imgs/pencil.png")}
                              className="img-fluid"
                            />
                          </a>
                          <a href="#">
                            {" "}
                            <img
                              src={require("../assets/images/registration_imgs/arrow-right.png")}
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="directLink"
                    disabled={true}
                    title="Direct link to live room"
                  >
                    <p>
                      Send this link to your attendees so they may register for
                      your webinar
                    </p>
                    <div class="modal_form mb-3">
                      <label for="exampleFormControlInput1" class="form-label">
                        Registration page
                      </label>
                      <div className="modal_text_box">
                        <input
                          type="email"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="https://:event.vaffix.com/register/1/ogw2kcw"
                        />
                        <div className="add_info_img">
                          <a href="#">
                            <img
                              src={require("../assets/images/registration_imgs/pencil.png")}
                              className="img-fluid"
                            />
                          </a>
                          <a href="#">
                            {" "}
                            <img
                              src={require("../assets/images/registration_imgs/arrow-right.png")}
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>

        {/*Delete Confirmation modal*/}
        <ConfirmationModal
          showModal={showConfirmationModal}
          confirmModal={submitDelete}
          hideModal={hideConfirmationModal}
          config={confirmationConfig}
          id={delMeetingId}
          loader={loader}
        />
      </div>

      <div className="popup1">
        <Modal
          size="lg"
          show={showPlayer}
          onHide={closeShowPlayer}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="model-head" closeButton>
            <Modal.Title className="model-title">
              Webinar configuration
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <iframe
                src={`${config.BBB_URL}/playback/presentation/2.3/${internalMeetingID}`}
                title="Vaffix"
                frameborder="0"
                style={{
                  overflow: "hidden",
                  height: "100px !important",
                  width: "100%",
                }}
                height="500px"
                width="100%"
              ></iframe>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export default MyWebinar;
