import React, { useRef, useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../components/Navbar";
import Innerfooter from "../components/inner-footer";
import Innerpagenavbar from "../components/innerpage-navbar";
import {
  Create_Webinar,
  create_Meeting,
  delete_Webinar,
  getDashboard_data,
  searchMeetings,
} from "../action/user.action";
import Chart from "../components/dashboard/Chart";
import BarChart from '../components/dashboard/BarChart'

const InnerHome = () => {
  const [dashboardData, setDashboardData] = useState({});
  const GetDashboard_details = async () => {
    try {
      const { result, status, testdata } = await getDashboard_data();
      console.log(result, status, testdata, "dashboard:=");
      setDashboardData(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetDashboard_details();
  }, []);

  return (
    <>
      <div className="nav_sec">
        <Header />
      </div>
      <div className="dashboard-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 ps-0">
              <Innerpagenavbar />
            </div>
            <div className="col-lg-10">
              <div className="dashbord-right">
                <div className="dashbord-right-body-1 dashboard-padding inner_home_page">
                  <div className="dashbord-right-header ">
                    <p>Dashboard</p>
                  </div>
                  <div className="inner_home_shadow">
                    <div className="inner_home_body">
                      <Row>
                        <Col xl={4} lg={6}>
                          <div className="inner_home_content">
                            <img
                              src={require("../assets/images/inner_dashboard_img.png")}
                              height="80"
                              width="80"
                              className="img-fluid"
                            />
                            <div>
                              <h5>{dashboardData?.totalNoOfSession}</h5>
                              <p>Total no of sessions created</p>
                            </div>
                          </div>
                        </Col>
                        <Col xl={4} lg={6}>
                          <div className="inner_home_content">
                            <img
                              src={require("../assets/images/inner_dashboard_img1.png")}
                              height="80"
                              width="80"
                              className="img-fluid"
                            />
                            <div>
                              {/* <h5>{dashboardData.notComplted}</h5> */}
                              <h5>{dashboardData?.upCommingSessions}</h5>
                              <p>Upcoming session </p>
                            </div>
                          </div>
                        </Col>
                        <Col xl={4} lg={6}>
                          <div className="inner_home_content">
                            <img
                              src={require("../assets/images/inner_dashboard_img2.png")}
                              height="80"
                              width="80"
                              className="img-fluid"
                            />
                            <div>
                              <h5>{dashboardData?.completedSession}</h5>
                              <p>Completed sessions</p>
                            </div>
                          </div>
                        </Col>
                        <Col xl={6} lg={6}>
                          <div className="inner_home_content mt-4">
                            <img
                              src={require("../assets/images/inner_dashboard_img3.png")}
                              height="80"
                              width="80"
                              className="img-fluid"
                            />
                            <div>
                              <h5>{dashboardData?.webinarCount}</h5>
                              <p>No of Webinars</p>
                            </div>
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div className="inner_home_content mt-4">
                            <img
                              src={require("../assets/images/inner_dashboard_img4.png")}
                              height="80"
                              width="80"
                              className="img-fluid"
                            />
                            <div>
                              <h5>{dashboardData?.registerUser}</h5>
                              <p>Total User Registered </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                 
                </div>
                <div className="mb-4">
                  <Chart dashboardData={dashboardData} />
                </div>
                <div className="mb-4">
                  <BarChart dashboardData={dashboardData} />
                </div>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </div>
      <Innerfooter />
    </>
  );
};
export default InnerHome;
