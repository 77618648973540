import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import TimeClockIcon from "../assets/images/time-clock.svg";
import InfoIcon from "../assets/images/infoIcon.svg";
import { Link, useNavigate } from "react-router-dom";
//Components
import InfoModal from "../components/common/modals/InfoModal";
import Innerpagenavbar from "../components/innerpage-navbar";
import Header from "../components/Navbar";
import Innerfooter from "../components/inner-footer";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
//API
import { getAll_Complted_meeting } from "../action/user.action";

import AddWebinarIcon from "../assets/images/addWebinarIcon.svg";

import Pagination from "../components/Pagination/Pagination";

//Filter Date- Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CompletedMeetings = (props) => {
  const { completedMeetings } = props;
  console.log("completed meetings:=", completedMeetings);
  const navigate = useNavigate();

  //---------------------- Date Filter -----------------------------
  const [selectedDate, setSelectedDate] = useState(null);
  //state
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [allCompletedMeetings, setAllCompletedMeetings] = useState([]);

  //---------------------Pagination - Date Filter--------------------

  const [currentPage, setCurrentPage] = useState(1);
  const meetingsPerPage = 5;

  const meetings = Array.isArray(allCompletedMeetings)
    ? allCompletedMeetings
    : [];

  //----------------------Date Filter -----------------------------

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log("Selected Date:", date);
    setCurrentPage(1);
  };

  // Filter meetings based on the selected date
  const filteredMeetings = selectedDate
    ? meetings.filter((meeting) => {
        const meetingDate = new Date(meeting.updatedAt);
        console.log("Meeting Start Time:", meetingDate);
        return meetingDate.toDateString() === selectedDate.toDateString();
      })
    : meetings;

  // Reset date function
  const handleReset = () => {
    setSelectedDate(null);
  };

  //----------------------Date Filter -----------------------------

  const [sessionsPerPage, setSessionsPerPage] = useState(5);

  // Pagination Calculations
  const lastSessionNumber = currentPage * sessionsPerPage;
  const firstSessionIndex = lastSessionNumber - sessionsPerPage;

  // Paginate
  const paginatedMeetings = filteredMeetings.slice(
    firstSessionIndex,
    lastSessionNumber
  );

  const totalSessionsCount = filteredMeetings.length;

  //---------------------Pagination--------------------
  // Calculate total pages for pagination
  const totalPages = Math.ceil(filteredMeetings.length / meetingsPerPage);

  console.log("totalPages:", totalPages);

  //   // Handle page change
  //   const handlePageChange = (pageNumber) => {
  //     setCurrentPage(pageNumber);
  //   };

  //----------------------Date Filter -----------------------------

  console.log("Filtered Meeting", paginatedMeetings);

  useEffect(() => {
    getAllCompletedMeetings();
  }, []);

  const getAllCompletedMeetings = async () => {
    try {
      const { status, result } = await getAll_Complted_meeting();
      if (status) {
        const completedSession = result.result.filter(
          (obj) => obj?.webinar_Details?.length != 0
        );
        setAllCompletedMeetings(completedSession);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hideConfirmationModal = () => {
    setShowConfirmationModal(false);
  };
  const showAnalytics = () => {
    setShowConfirmationModal(true);
  };
  const InfoHandler = async (id, type) => {
    navigate("/WebinarAnalytics", { state: { result: id, type: type } });
  };
  // const convertTime = (sessiondate, timezone) => {
  //   // Parse the sessiondate assuming it's in ISO 8601 format and in UTC
  //   const originalDateTime = moment.utc(sessiondate).tz(timezone);
  //   console.log("originalDateTime=====", originalDateTime.format());

  //   // Format to the desired output
  //   const formattedDate = originalDateTime.format("MM/DD/YYYY hh:mm A");
  //   return formattedDate;
  // };

  const convertTime = (sessiondate, timezone) => {
    console.log(sessiondate, timezone);
    const localTime = moment(sessiondate);

    const pacificMidwayDateTime = localTime.tz(timezone);

    console.log(
      pacificMidwayDateTime.format("MM/DD/YYYY hh:mm A"),
      "istDateTime1"
    );

    return pacificMidwayDateTime.format("MM/DD/YYYY hh:mm A");
  };
  //send mail model
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //bulk send mail model
  const [bulkshow, setbulkShow] = useState(false);
  const handlebulkClose = () => setbulkShow(false);
  const handlebulkShow = () => setbulkShow(true);
  const options = [
    { value: "0", label: "All" },
    { value: "1", label: "Completed Meeting" },
    { value: "2", label: "In-Complete Meetings" },
    { value: "4", label: "Upcoming Meetings" },
    { value: "5", label: "Upcoming Meetings" },
    { value: "6", label: "Upcoming Meetings" },
    { value: "7", label: "Upcoming Meetings" },
    { value: "8", label: "Upcoming Meetings" },
    { value: "9", label: "Upcoming Meetings" },
    { value: "10", label: "Upcoming Meetings" },
    { value: "11", label: "Upcoming Meetings" },
    { value: "12", label: "Upcoming Meetings" },
    { value: "13", label: "Upcoming Meetings" },
    { value: "14", label: "Upcoming Meetings" },
    { value: "15", label: "Upcoming Meetings" },
    { value: "16", label: "Upcoming Meetings" },
    { value: "17", label: "Upcoming Meetings" },
    { value: "18", label: "Upcoming Meetings" },
  ];
  return (
    <>
      <div className="nav_sec">
        <Header />
      </div>
      <div className="dashboard-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 ps-0">
              <Innerpagenavbar />
            </div>

            <div className="col-lg-10">
              <div className="dashbord-right">
                <div className="dashbord-right-body-1 dashboard-padding">
                  <div className="dashbord-right-header">
                    <p>My Report</p>
                    <div className="datapick text-end mb-3">
                      <button className="primary_btn" onClick={handlebulkShow}>
                        Bulk Send Email
                      </button>
                      <div>
                        <DatePicker
                          selected={selectedDate}
                          showIcon
                          icon={
                            <svg
                              width="18"
                              height="20"
                              viewBox="0 0 18 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.168 14.0833L10.418 13.75"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.168 15.9165L10.418 16.2498"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.832 1.6665V4.99984"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12.5833 12.6665L12.25 11.9165"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12.5833 17.333L12.25 18.083"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M14.7513 11.9165L14.418 12.6665"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M14.7513 18.083L14.418 17.333"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M16 8.74967V4.99967C16 4.55765 15.8244 4.13372 15.5118 3.82116C15.1993 3.5086 14.7754 3.33301 14.3333 3.33301H2.66667C2.22464 3.33301 1.80072 3.5086 1.48816 3.82116C1.17559 4.13372 1 4.55765 1 4.99967V16.6663C1 17.1084 1.17559 17.5323 1.48816 17.8449C1.80072 18.1574 2.22464 18.333 2.66667 18.333H7.66667"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M16.582 13.75L15.832 14.0833"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M16.582 16.2498L15.832 15.9165"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M1 8.33301H16"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M5.16797 1.6665V4.99984"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M13.5 17.5C14.8807 17.5 16 16.3807 16 15C16 13.6193 14.8807 12.5 13.5 12.5C12.1193 12.5 11 13.6193 11 15C11 16.3807 12.1193 17.5 13.5 17.5Z"
                                stroke="#11777E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          }
                          className="datapick_head"
                          isClearable
                          onChange={handleDateChange}
                          dateFormat="yyyy/MM/dd"
                          placeholderText="Select a date"
                        />
                      </div>
                    </div>
                  </div>

                  {paginatedMeetings?.length > 0 ? (
                    <>
                      {paginatedMeetings.map((meetings, index) => (
                        <>
                          {meetings?.webinar_Details?.length > 0 && (
                            <>
                              <div className="dashbord-right-body mb-3">
                                <div className="dashbordrightbody-header dashbordrightbody-header1">
                                  <div className="titleCount">
                                    <p>{meetings?.title}</p>
                                  </div>
                                </div>
                                <div className="scroll-auto1">
                                  {meetings?.webinar_Details?.map(function (
                                    session,
                                    index
                                  ) {
                                    return (
                                      <div className="dashbordrightbody-body">
                                        <div className="dashbordrightbody-list1">
                                          <div className="dashbordrightbody-list">
                                            <div className="dashbordrightbody-content">
                                              <img
                                                src={TimeClockIcon}
                                                alt="img"
                                                className="img-fluid"
                                              />
                                              <p>
                                                Session Time :
                                                {convertTime(
                                                  session?.startDate,
                                                  session?.timeZone
                                                )}
                                              </p>
                                              {/* <p>
                                                TimeZone:
                                                {session?.timeZone}
                                              </p> */}
                                            </div>

                                            <div className="dashboard_right_body_1">
                                              <button
                                                className="primary_btn2"
                                                onClick={() =>
                                                  InfoHandler(session._id, 1)
                                                }
                                              >
                                                <img
                                                  src={InfoIcon}
                                                  height="16"
                                                  width="16"
                                                  className="img-fluid playIcon"
                                                />
                                                Info
                                              </button>
                                              <button
                                                className="primary_btn"
                                                onClick={handleShow}
                                              >
                                                Send Email
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="nomeeting_img">
                        <h2 className="no-meeting">No Completed Meetings</h2>
                        <img
                          src={require("../assets/images/no-meeting.png")}
                          className="mx-auto d-block img-fluid"
                        />
                      </div>
                    </>
                  )}

                  <>
                    {/* Pagination */}
                    {totalSessionsCount > 0 && (
                      <>
                        {/* Pagination */}
                        <div className="pagination-flex">
                          <div>
                            <label htmlFor="itemsPerPage">
                              Items per page:{" "}
                            </label>
                            <select
                              id="itemsPerPage"
                              value={sessionsPerPage}
                              onChange={(e) =>
                                setSessionsPerPage(Number(e.target.value))
                              }
                            >
                              <option value={5}>5</option>
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                            </select>
                          </div>
                          {/* Pagination */}
                          <div className="items-per-page-selector">
                            <Pagination
                              itemsCount={totalSessionsCount}
                              itemsPerPage={sessionsPerPage}
                              currentPage={currentPage}
                              setCurrentPage={setCurrentPage}
                              alwaysShown={false}
                            />
                            {/* Add a dropdown */}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Innerfooter />

      <Modal show={show} onHide={handleClose} className="email-model">
        <Modal.Header className="model-head" closeButton>
          <Modal.Title className="model-title">Send Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Form.Label htmlFor="inputPassword5">Subject</Form.Label>
          <Form.Control
            type="text"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
          />{" "}
          <Form.Label htmlFor="inputPassword5" className="mt-2">
            Discription
          </Form.Label>
          <Form.Control
            as="textarea"
            id="message"
            aria-label="With textarea"
            rows={5}
            placeholder=""
          />
          <div className="text-center mt-3">
            <button className="primary_btn">Submit</button>
          </div>
        </Modal.Body>
        <Modal.Footer className="footer-model"></Modal.Footer>
      </Modal>
      <Modal show={bulkshow} onHide={handlebulkClose} className="email-model">
        <Modal.Header className="model-head" closeButton>
          <Modal.Title className="model-title">Send Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label htmlFor="inputPassword5">Select Email</Form.Label>
          <Select
            isMulti
            id="filterSessions"
            className="filterSessions mb-2"
            // onChange={FilterHandler}
            options={options}
            theme={(theme) => ({
              ...theme,
              borderRadius: "5px",
              width: "100%",
              colors: {
                ...theme.colors,
                primary25: "#F4F8FC",
                neutral20: "#dbdfe3 ",
                neutral30: "dbdfe3",
                primary: "#dbdfe3",
                // neutral20: "#696e70",
                neutral50: "#696e70 ",
                neutral60: "#696e70",
                neutral40: "#F4F8FC",
                primary50: "#F4F8FC",
                neutral80: "#696e70",
                dangerLight: "F4F8FC",
                danger: "696e70",
              },
            })}
          />
          <Form.Label htmlFor="inputPassword5">Subject</Form.Label>
          <Form.Control
            type="text"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
          />{" "}
          <Form.Label htmlFor="inputPassword5" className="mt-2">
            Discription
          </Form.Label>
          <Form.Control
            as="textarea"
            id="message"
            aria-label="With textarea"
            rows={5}
            placeholder=""
          />
        </Modal.Body>
        <div className="text-center mt-2">
          <button className="primary_btn">Submit</button>
        </div>
        <Modal.Footer className="footer-model"></Modal.Footer>
      </Modal>
    </>
  );
};
export default CompletedMeetings;
