import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Form, Button, InputGroup, Col, Row, Image } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Header from "../components/Navbar";
import Innerfooter from "../components/inner-footer";

import Innerpagenavbar from "../components/innerpage-navbar";

const Subscription = () => {
  return (
    <>
      <div className="nav_sec">
        <Header />
      </div>
      <div className="dashboard-page ">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 ps-0">
              <Innerpagenavbar />
            </div>
            <div className="col-lg-10">
              <div className="dashbord-right Subscription-right">
                <div className="head">
                  <h2>Subscription plan</h2>
                  <p>
                    Choose the plan that fits your needs, and experience the
                    full power of Vaffix for the next 30, 60, or 90 days!
                  </p>
                </div>
                <div>
                  <Row>
                    <Col lg="4" md="6" sm="6">
                      <div className="subscription-box">
                        <div className="content1">
                          <h4>Vaffix Lite</h4>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's
                          </p>
                        </div>
                        <div className="content2">
                          <h1>$100</h1>
                          <h6>30 Days Access</h6>
                        </div>
                        <div className="content3">
                          <button className="primary_btn">
                            Choose this plan
                          </button>
                        </div>
                        <div className="content4">
                          <p>
                            The Vaffix Lite plan is ideal for individuals or
                            small teams who need temporary access to powerful
                            video conferencing tools. Whether you’re hosting a
                            single event, running a short-term project, or
                            conducting a series of webinars, this plan offers
                            you the flexibility to use Vaffix’s premium features
                            without the long-term commitment. Get everything you
                            need to collaborate effectively for 30 days.
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                      <div className="subscription-box subscription-box-batch">
                        <div className="batch">Best Value</div>
                        <div className="content1">
                          <h4>Vaffix Lite</h4>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's
                          </p>
                        </div>
                        <div className="content2">
                          <h1>$100</h1>
                          <h6>30 Days Access</h6>
                        </div>
                        <div className="content3">
                          <button className="primary_btn">
                            Choose this plan
                          </button>
                        </div>
                        <div className="content4">
                          <p>
                            The Vaffix Lite plan is ideal for individuals or
                            small teams who need temporary access to powerful
                            video conferencing tools. Whether you’re hosting a
                            single event, running a short-term project, or
                            conducting a series of webinars, this plan offers
                            you the flexibility to use Vaffix’s premium features
                            without the long-term commitment. Get everything you
                            need to collaborate effectively for 30 days.
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg="4" md="6" sm="6">
                      <div className="subscription-box">
                        <div className="content1">
                          <h4>Vaffix Lite</h4>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's
                          </p>
                        </div>
                        <div className="content2">
                          <h1>$100</h1>
                          <h6>30 Days Access</h6>
                        </div>
                        <div className="content3">
                          <button className="primary_btn">
                            Choose this plan
                          </button>
                        </div>
                        <div className="content4">
                          <p>
                            The Vaffix Lite plan is ideal for individuals or
                            small teams who need temporary access to powerful
                            video conferencing tools. Whether you’re hosting a
                            single event, running a short-term project, or
                            conducting a series of webinars, this plan offers
                            you the flexibility to use Vaffix’s premium features
                            without the long-term commitment. Get everything you
                            need to collaborate effectively for 30 days.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Innerfooter />
    </>
  );
};
export default Subscription;
