import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PreviewIcon from "../../assets/images/previewIcon.svg";
import CustomizeIcon from "../../assets/images/customizeIcon.svg";

//API
import { getRegistartionTemplate } from "../../action/user.action";

function TemplateFullconfig(props) {
  //PROPS
  const { webinarId, templateId, meetingType } = props;
  // alert(meetingType)
  //SAVE / EDIT - CONFIG
  //SAVE = "none"
  const OPT = templateId == 0 ? 0 : templateId;
  console.log("template id:=", templateId, OPT);

  const [templateData, setTemplateData] = useState([]);
  const templatePreviewData = new Array(
    {
      screen: require("../../assets/images/template_img/modal_img_2.jpg"),
    },
    {
      screen: require("../../assets/images/template_img/modal_img_3.jpg"),
    },
    {
      screen: require("../../assets/images/template_img/modal_img_4.jpg"),
    },
    {
      screen: require("../../assets/images/template_img/modal_img_5.jpg"),
    },
    {
      screen: require("../../assets/images/template_img/modal_img_6.jpg"),
    },
    {
      screen: require("../../assets/images/template_img/modal_img_7.jpg"),
    }
  );
  const [activeBody, setActiveBody] = useState(OPT);
  const handleBodyClick = (bodyId) => {
    setActiveBody(bodyId);
  };
  const [show1, setShow1] = useState(false);
  const [template, setTemplate] = useState("");
  const handleClose_1 = () => setShow1(false);
  const handleShow1 = (index) => {
    setShow1(true);
    setTemplatePreview(index);
  };
  const [show2, setShow2] = useState(false);
  const [templatePreview, setTemplatePreview] = useState(0);
  const handleClose_2 = () => setShow2(false);
  const handleShow2 = (template) => {
    setShow2(true);
    setTemplate(template);
  };

  useEffect(() => {
    getTemplate();
  }, []);
  //GET - ALL TEMPLATE
  const getTemplate = async () => {
    //PAYLOAD
    let payload = {
      templateId: "",
      webinarId: webinarId,
    };

    try {
      let { status, result } = await getRegistartionTemplate(payload);
      console.log(status, result, "status, result");
      if (status) {
        setTemplateData(result?.result);
      }
    } catch (err) {
      console.log(err, "err");
    } finally {
    }
  };

//------------------------------------------------------------------------------------------------

const filteredTemplateData = templateData.filter(obj => obj.identifier !== "CanvaTempalte");

//------------------------------------------------------------------------------------------------

  console.log(filteredTemplateData, "templateDatatemplateDatatt");
  return (
    <>
      <div className="dashbordrightbody-list1 registration_radio">
        <div className="registration_sec ">
         
          <div className="row">
          {filteredTemplateData.map((obj, index) => {
            return (
              <div className="col-lg-4 mt-2 mb-3">
                {/* <a
                  href="#"
                  className={`registration_body ${
                    obj.custamiz_id ? "select" : ""
                  } ${activeBody === 2 ? "active" : ""}`}
                  onClick={() => handleBodyClick(2)}
                > */}
                <a
                  href="#"
                  className={`registration_body ${
                    OPT === index
                      ? "select"
                      : OPT === obj?.custamiz_id
                      ? "select"
                      : ""
                  }`}
                  onClick={() => handleBodyClick(index)}
                >
                  <div className="registration_sec_image">
                    <Image
                      src={`${templatePreviewData[index]?.screen}`}
                      className="img-fluid m-auto d-block ps-0"
                      style={{ width: "328px", height: "150px" }}
                    />
                  </div>
                  <div className="registration_info mt-3">
                    <div className="registration_info_1">
                      
                        <p>Template name : <span>Vaf 00{index + 1}</span>{" "}</p>
                          <div>
                          <img
                        src={CustomizeIcon}
                        className="img-fluid ps-0 pe-2"
                      />
                      <a
                        href={`/${obj?.identifier}/${webinarId}/${
                          obj.custamiz_id ? obj.custamiz_id : obj?._id
                        }/${meetingType}`}
                      >
                        Customize
                      </a>
                          </div>
                    </div>

                    <div >
                    <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">View template</Tooltip>
                        }
                      >
                        <p>
                         
                          <img
                            src={PreviewIcon}
                            className="img-fluid"
                          />{" "}
                        </p>
                      </OverlayTrigger>
                      
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
          </div>
         
        </div>
      </div>

      <Modal
        show={show1}
        onHide={handleClose_1}
        size="lg"
        className="template_modal_2"
        enforceFocus="false"
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            class="img-fluid mb-1 mt-1"
            src={templatePreviewData?.[templatePreview]?.screen}
            alt="group image"
          />
        </Modal.Body>
      </Modal>
      {/* <Modal show={show2} onHide={handleClose_2} className="template_customize">
        <Modal.Body>
          {(template === "template1") && (
            <Template_1 handleClose_2={handleClose_2} tid={OPT} id={webinarId} />
          )}

          {(template === "template2") && (
            <Template_2 handleClose_2={handleClose_2} tid={OPT} id={webinarId} />
          )}

          {(template === "template3") && (
            <Template_3 handleClose_2={handleClose_2} tid={OPT} id={webinarId} />
          )}

          {(template === "template4") && (
            <Template_4 handleClose_2={handleClose_2} tid={OPT} id={webinarId} />
          )}

          {(template === "template5") && (
            <Template_5 handleClose_2={handleClose_2} tid={OPT} id={webinarId} />
          )}

          {(template === "template6") && (
            <Template_6 handleClose_2={handleClose_2} tid={OPT} id={webinarId} />
          )}

        </Modal.Body>
      </Modal> */}
    </>
  );
}
export default TemplateFullconfig;
