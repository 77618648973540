import React, { useEffect, useRef, useState } from "react";
// import Innerpagenavbar from "../components/innerpage-navbar";
import Form from "react-bootstrap/Form";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

import { Accordion, Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Basic_validation,
  persenter_validation,
} from "../../../lib/validation";
import { decryptString, encryptString, isEmpty } from "../../../helper/helper";
import {
  Create_Webinar,
  checkForEmail_verification,
  create_Meeting,
  update_Webinar,
} from "../../../action/user.action";
import {
  setWebinar,
  Webinar_Details,
} from "../../../redux/features/webinar_details";
import { toastAlert } from "../../../utils/toast";
import ReactLanguageSelect from "react-languages-select";
import config from "../../../lib/config";
import Header from "../../../components/Navbar";
import Innerpagenavbar from "../../../components/innerpage-navbar";
import Innerfooter from "../../../components/inner-footer";

import PropagateLoader from "react-spinners/PropagateLoader";

import "react-languages-select/css/react-languages-select.css";

const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999",
};

const initalForm = {
  Webinar_title: "",
  Webinar_language: "en",
  Webinar_description: "",
  Webinar_name: "",
  edit: false,
};

function NewBasicSettings() {
  const location = useLocation();

  const { id } = useParams();

  // const [id, setId] = useState(
  //   location.state == undefined ? {} : location.state.result
  // );
  console.log(id, "id------basicSetting");
  const dispatch = useDispatch();

  const fileRef = useRef();

  const navigate = useNavigate();

  const [previewUrl, setpreviewUrl] = useState("");
  const [decryptStr, setDecryptString] = useState();
  const [isOTPSent, setIsotpSent] = useState(false);

  const userDetails = useSelector((state) => state.User);
  console.log(userDetails, "userDetails");

  const [loading, setloading] = useState(false);

  const [initialState, setInitialState] = useState(initalForm);
  const [edit_presenter, setEdit_Presenter] = useState(true);
  const [newPresenter, setnewPresenter] = useState({
    preview: "",
    profile_image: "",
    Presenters_name: "",
    Presenters_email: "",
    edit: false,
  });

  const { preview, profile_image, Presenters_name, Presenters_email } =
    newPresenter;

  const [values, setValues] = useState([]);
  const [error, setError] = useState();
  const [saveBtnDisable, setSaveBtnDisable] = useState(true);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Changes_Lang_Handler = (value) => {
    setInitialState({ ...initialState, Webinar_language: value });
  };

  const changesHandler = async (e) => {
    const { id, value } = e.target;

    if (id == "Webinar_title") {
      console.log("test:=", id, value?.length);
      if (value?.length <= 30) {
        setInitialState({ ...initialState, [id]: value });
      }
    } else if (id == "Webinar_description") {
      if (value?.length <= 150) {
        setInitialState({ ...initialState, [id]: value });
      }
    }
  };

  const getModurater = () => {
    console.log(userDetails, 'userDetailsuserDetailsuserDetails')
    setValues([
      {
        profile_image: userDetails.profile_image,
        Presenters_name: userDetails.userName,
        Presenters_email: userDetails.email,
      },
    ]);
  };

  const decodeFun = async () => {
    const decodeid = await decryptString(id, true);

    console.log(JSON.parse(decodeid), "decodeid");

    setDecryptString(JSON.parse(decodeid));
  };
  useEffect(() => {
    decodeFun();
  }, [id]);

  useEffect(() => {
    getModurater();
  }, [userDetails]);

  const editPresenters = async (type, val, i) => {
    console.log(val, "val");

    if (!val.profile_image) {
      setpreviewUrl(URL.createObjectURL(val.preview));
    } else {
      setpreviewUrl( `${config.API_URL}/presenter/${val.profile_image}`)
  
    }
    setnewPresenter({ ...val });
    setSaveBtnDisable(false);

    setEdit_Presenter(false);
  };

  const addClick = async () => {
    newPresenter.isOTPSent = isOTPSent;
    const dat = await persenter_validation(newPresenter);
    console.log(dat, newPresenter, "newPresenter---11111111");
    if (isEmpty(dat)) {
      const payload = {
        email: newPresenter.Presenters_email,
      };

      if (isOTPSent && newPresenter.otp) {
        payload.OTP = newPresenter.otp;
      }

      const { status, result } = await checkForEmail_verification(payload);

      console.log(status, result, "status,result");
      if (status) {
        if (result.isMailSent) {
          setIsotpSent(true);
          toastAlert("success", result.message);
        } else {
          if (edit_presenter) {
            newPresenter.edit = true;
          }
          setValues([newPresenter]);
          setnewPresenter({
            preview: "",
            image: "",
            Presenters_name: "",
            Presenters_email: "",
            edit: false,
          });
          setpreviewUrl("");
          setError();
          setSaveBtnDisable(true);
          setEdit_Presenter(true);
          setIsotpSent(false);
          toastAlert("success", "Presenter verified");
        }
      } else {
        toastAlert("error", result.message);
      }
    } else {
      setError(dat);
    }
  };

  const submitFormOne = async (e) => {
    try {
      e.preventDefault();

      const dat = await Basic_validation(initialState);

      console.log(initialState, "initialState");

      if (isEmpty(dat) && values.length != 0) {
        setloading(true);
        const dep = await decryptString(id, true);

        console.log(dep, "dep");

        const payload = {
          presenters: values,
          ...initialState,
          ...JSON.parse(dep),
        };
        console.log(payload, "payload");

        const { status, result } = await Create_Webinar(payload);

        console.log(status, result, "status, result");
        if (status) {
          if (decryptStr?.type == "Instant Meeting") {
            toastAlert("success", "Meeting created successfully");

            handleShow();
            // navigate("/webinar");
          } else {
            console.log("result", result.result._id);
            // const encryptId = await encryptString(result.result._id, true)
            toastAlert("success", "Meeting created successfully");
            navigate(`/express-edit/${result.result._id}`, {
              state: { type: "new" },
            });
          }
          // const tkn = encryptString(result.result._id, true);
          // setTimeout(() => {
          //     window.location.reload();              
          // }, 1000);
          // dispatch(setWebinar(result.result));
        }
      } else {
        if (values.length == 0) {
          toastAlert("error", "Presenter is required");
        }
        setError(dat);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  function handleListChange(event) {
    console.log("track presenter:=", values?.[0]?.Presenters_name);
    const { id } = event.target;
    if (id == "image") {
      const file = event.target.files[0];
      const fileType = file.type;
      const fileSize = file.size;

      console.log(fileType, fileSize, "fileSizefileSizefileSize");

      if (
        (fileType === "image/png" ||
          fileType === "image/jpeg" ||
          fileType === "image/jpg" ||
          fileType === "image/webp") &&
        fileSize <= 1024 * 1024 // 1MB in bytes
      ) {
        setEdit_Presenter(false);
        const { files } = event.target;
        setpreviewUrl(URL.createObjectURL(files[0]));
        setnewPresenter({
          ...newPresenter,
          [id]: files,
          preview: URL.createObjectURL(files[0]),
        });
      } else {
        toastAlert("error", "The file must be less than 1 MB.");
        return false;
      }
    } else {
      setnewPresenter({ ...newPresenter, [id]: event.target.value });
    }
  }

  console.log(edit_presenter, "decryptStr");

  const completeWebinar = () => {
    //navigate("/webinar");
    window.location.href = `/webinar`;
  };
  
  return (
    <>
      {loading && (
        <div className="loadBefore">
          <PropagateLoader
            color={"#0d2a35"}
            loading={loading}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <div className="nav_sec">
        <Header />
      </div>
      <div className="dashboard-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 ps-0">
              <Innerpagenavbar />
            </div>
            <div className="col-lg-10">
              <div className="dashbord-right">
                <div className="dashbord-right-body-1 dashboard-padding">
                  <Accordion
                    className="accMain"
                    activeKey={["0"]}
                    alwaysOpen
                    classNam="accordion-button"
                  >
                    <Accordion.Item eventKey="0" className="accItem">
                      <Accordion.Header>
                        <p>Basic settings</p>
                        <div className="accordion-hearder-content">
                          <div>
                            <Link
                              to="/webinar"
                              className="primary_btn primary_btn2 me-4"
                            >

                              Cancel
                            </Link>
                            <Link
                              to="#"
                              className="primary_btn"
                              onClick={submitFormOne}
                            >


                              Confirm
                            </Link>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="white-box webinar-setting-content basic-margin">
                          <Row>
                            <Col lg="6">
                              {" "}
                              <Form.Group
                                className="mb-3 "
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Webinar language </Form.Label>{" "}
                                <ReactLanguageSelect
                                  defaultLanguage="en"
                                  names={"international"}
                                  // languages={languages.map(lang => lang.code)}
                                  // searchable={true}
                                  searchPlaceholder="Search language"
                                  aria-label="Default select example"
                                  id="Webinar_language"
                                  onSelect={(val) => Changes_Lang_Handler(val)}
                                  className="language_select"
                                  disabled={true}
                                />
                                {error && error.Webinar_language && (
                                  <span style={{ color: "red" }}>
                                    {error.Webinar_language}
                                  </span>
                                )}
                                <p>
                                  The interface will be translated for your users
                                </p>
                              </Form.Group>
                            </Col>
                            <Col lg="6">
                              {" "}
                              <Form.Group
                                className="mb-3"
                                controlId="Webinar_title"
                              >
                                <Form.Label>Webinar title</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Example: How to lose weight in 6 weeks"
                                  //id="Webinar_title"
                                  onChange={changesHandler}
                                  value={initialState?.Webinar_title}
                                />
                                <p>Public title. Max 30 characters.</p>
                                {error && error.Webinar_title && (
                                  <span style={{ color: "red" }}>
                                    {error.Webinar_title}
                                  </span>
                                )}
                              </Form.Group>
                            </Col>
                            <Col lg="6">
                              {" "}
                              <Form.Group
                                className="mb-3"
                                controlId="Webinar_description"
                              >
                                <Form.Label>Webinar description</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Example: All the tips and tricks to efficient diets and workouts"
                                  //id="Webinar_description"
                                  onChange={changesHandler}
                                  value={initialState?.Webinar_description}
                                />
                                <p>Public description. Max 150 characters.</p>
                                {error && error.Webinar_description && (
                                  <span style={{ color: "red" }}>
                                    {error.Webinar_description}
                                  </span>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                        <h2 className="head-field mt-3">Webinar presenter</h2>
                        <Row className=" mb-5">
                          <Col xxl="6" xl="12" lg="12" className="mb-3">
                            {" "}
                            <div className=" webinar-width1 webinar-width2">
                              <div
                                className="white-box1"
                                style={{ minHeight: "215px" }}
                              >
                                <div className=" NewPresenters">
                                  <Row className="align-items-center">
                                    <div className="col-lg-12">
                                      <div className="row align-items-center">
                                        <div className="col-lg-2 col-md-2 col-sm-2">
                                          <div className="img_position_basic">
                                            <Form.Control
                                              ref={fileRef}
                                              id="image"
                                              type="file"
                                              className="img_upload"
                                              accept=".png,.jpg,.jpeg,.webp"
                                              // value={ }
                                              onChange={handleListChange}
                                              disabled={edit_presenter}
                                            />
                                            {/* <i class="bi bi-folder-plus upload"  style={{ fontSize: '30px' }}></i> */}
                                            {previewUrl != undefined &&
                                              previewUrl != "" ? (
                                              <img
                                                src={previewUrl}
                                                onClick={() => fileRef.current.click()}
                                                className="img-fluid "
                                                style={{
                                                  height: "60px",
                                                  width: "60px",
                                                  borderRadius: "50%",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            ) : (
                                              <>
                                                <img
                                                  src={require("../../../assets/images/profile-img2.png")}
                                                  onClick={() =>
                                                    fileRef.current.click()
                                                  }
                                                  className="img-fluid image-dp"
                                                  style={{
                                                    height: "60px",
                                                    width: "60px",
                                                    borderRadius: "50%",
                                                    cursor: "pointer",
                                                  }}
                                                />

                                                {/* {error && error.image && <span style={{ color: 'red' }}>{error.image}</span>} */}
                                              </>
                                            )}
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip id="tooltip-disabled">
                                                  Upload image
                                                </Tooltip>
                                              }
                                            >

                                              <img
                                                src={require("../../../assets/images/edit.png")}
                                                className="image_edit"
                                                onClick={() => fileRef.current.click()}
                                              />
                                            </OverlayTrigger>

                                            {error && error.image && (
                                              <span style={{ color: "red" }}>
                                                {error.image}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6">

                                          <p
                                            style={{
                                              fontSize: "12px",
                                              color: "#878682",
                                            }}
                                          >
                                            Image is optional, Max 1MB. Valid formats:
                                            png , jpg , jpeg and webp
                                          </p>

                                        </div>

                                      </div>
                                    </div>


                                    <Col
                                      lg="12" className="img_position_basic1 mt-3"
                                    >
                                      <Row className="align-items-center img_position_basic2">

                                        <div className="">
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter name "
                                            className="mb-3"
                                            id="Presenters_name"
                                            value={
                                              Presenters_name ? Presenters_name : ""
                                            }
                                            onChange={handleListChange}
                                            disabled={edit_presenter}
                                          />
                                          {error && error.Presenters_name && (
                                            <span style={{ color: "red" }}>
                                              {error.Presenters_name}
                                            </span>
                                          )}
                                          <Form.Control
                                            type="text"
                                            placeholder="Email Address"
                                            className="mb-3"
                                            id="Presenters_email"
                                            value={
                                              Presenters_email
                                                ? Presenters_email
                                                : ""
                                            }
                                            onChange={handleListChange}
                                            disabled={edit_presenter}
                                          />{" "}
                                          {error && error.Presenters_email && (
                                            <span style={{ color: "red" }}>
                                              {error.Presenters_email}
                                            </span>
                                          )}
                                        </div>
                                        <div className="web_display">
                                          {isOTPSent && (
                                            <>
                                              {" "}
                                              <Form.Control
                                                type="text"
                                                placeholder="Enter OTP"
                                                className="mb-3"
                                                id="otp"
                                                onChange={handleListChange}
                                              />{" "}
                                              {error && error.Presenters_otp && (
                                                <span style={{ color: "red" }}>
                                                  {error.Presenters_otp}
                                                </span>
                                              )}
                                            </>
                                          )}

                                          <div>
                                            <button
                                              className="profile-edit"
                                              disabled={edit_presenter}
                                              type="button"
                                              onClick={addClick}
                                            >
                                              Preview
                                            </button>
                                          </div>
                                        </div>

                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col xxl="6" xl="12" lg="12" className="mb-3">
                            {values?.length > 0 && (
                              <>
                                <div
                                  className="white-box1 "
                                  style={{ minHeight: "100px", background: "#f3f7f9" }}
                                >
                                  {values.map((list, i) => (
                                    <Row className="align-items-center present-profile">
                                      <Col
                                        lg="2" md="2" sm="2" className=""
                                      >
                                        {" "}
                                        {list.edit ? (
                                          <div className="img_position_basic">
                                            <img
                                              src={`${config.API_URL}/presenter/${list.profile_image}`}
                                              className="img-fluid"
                                              style={{
                                                height: "60px",
                                                width: "60px",
                                                borderRadius: "50%",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <>
                                            {list.preview ? (
                                              <div className="img_position_basic">
                                                <img
                                                  src={list.preview}
                                                  className="img-fluid"
                                                  style={{
                                                    height: "60px",
                                                    width: "60px",
                                                    borderRadius: "50%",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </div>
                                            ) : list?.profile_image != null ? (
                                              <div className="img_position_basic">
                                                <img
                                                  src={`${config.API_URL}/presenter/${list.profile_image}`}
                                                  style={{
                                                    height: "60px",
                                                    width: "60px",
                                                    borderRadius: "50%",
                                                  }}
                                                />
                                              </div>
                                            ) : (
                                              <div className="img_position_basic">
                                                <img
                                                  src={require("../../../assets/images/profile-img2.png")}
                                                  style={{
                                                    height: "60px",
                                                    width: "60px",
                                                    borderRadius: "50%",
                                                  }}
                                                />
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </Col>
                                      <Col xxl="10" xl="9" lg="10" md="10" sm="10">
                                        {" "}
                                        <div className="profile-detail-align-start">
                                          <div>

                                            <p className="name-profile">{list.Presenters_name}</p>

                                            <p className="email-profile">
                                              {list.Presenters_email}
                                            </p>


                                          </div>{" "}
                                          <div className="profile-change mt-2">

                                            <a
                                              className="primary_btn"
                                              style={{ minWidth: "42px" }}
                                              onClick={() =>
                                                editPresenters(false, list, i)
                                              }
                                            >
                                              Editing
                                            </a>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  ))}
                                </div>
                              </>
                            )}
                          </Col>

                          {/* {!saveBtnDisable && ( */}

                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Innerfooter />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        className="finish_model"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className=" instant-meeting-head">
            <div className=" instant-meeting-box completed_meeting">
              {/* <div className="white-box">
                            <div>

                            </div> */}
              <center></center>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <p>Webinar Session Has Completed</p> */}
                <img
                  src={require("../../../assets/images/group.png")}
                  className="img-fluid"
                />{" "}
              </div>
              <div className="finishpage">
                <h2 className="mb-3">Congratulations</h2>
                <p>Your Webinar has been successfully created</p>
              </div>
              <center>
                <button onClick={completeWebinar} className="primary_btn mt-3">
                  Go To Webinar List
                </button>
              </center>
              {/* </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NewBasicSettings;
