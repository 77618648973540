import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Innerpagenavbar from "../components/innerpage-navbar";
import Header from "../components/Navbar";
import { getAllUser_InWebinar, getAllWebinar } from "../action/user.action";

import moment, { now } from "moment";
import Innerfooter from "../components/inner-footer";

//COMPONENTS
import { toastAlert } from "../utils/toast";
import Select from "react-select";
import { Button } from "react-bootstrap";

const InnerActiveRegistration = () => {
  const location = useLocation();
  let newLoc = location?.state?.meetings
  // const { session_id, webinar_id } = location?.state;
  const [formvalue, setFormvalue] = useState({ webinar_id: "1" });
  const [werbibar_details, setWebinarDetails] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [userList, setUserList] = useState([]);
  // const [selectedWeb, setSelectedWeb] = useState(null)
  const [selTab, setSelTab] = useState({
    webinar: null,
    session: null
  })
  const { webinar, session } = selTab
  useEffect(() => {
    getAllmeetings();
    getSpecificMeetingUserInfo();
  }, []);

  const getSpecificMeetingUserInfo = async () => {
    console.log(newLoc, "location", location.state)
    let newSes = location?.state?.session

    if (newSes?._id && newLoc._id) {
      let sendData = {
        session_id: newSes?._id,
        webinar_id: newLoc?._id
      }
      const { result, status } = await getAllUser_InWebinar(sendData);
      console.log("result30", result)
      setUserList(result.result);
      setSelTab({ ...selTab, ...{ webinar: { value: newLoc._id, label: newLoc.title }, session: { value: newSes?._id, label: convertTime(newSes?.startDate, newSes?.timeZone) } } })
      setFormvalue((prevFormValue) => ({
        ...prevFormValue,
        webinar_id: newLoc?._id,
        session_id: newSes?._id // Reset session_id since a new webinar was selected
      }));
    }
  };

  const getAllmeetings = async () => {
    try {
      console.log("hiiii");
      const { status, result } = await getAllWebinar();

      console.log(status, result, "sakthi ----- dashboard");
      setWebinarDetails(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  const Submithandler = async () => {
    try {
      console.log(formvalue, "formvalue");
      if (!formvalue.webinar_id) {
        toastAlert("error", "Select webinar");
        return;
      }

      if (!formvalue.session_id) {
        toastAlert("error", "Select sesssion");
        return;
      }
      const { result, status } = await getAllUser_InWebinar(formvalue);

      console.log(result, status, "result,status");

      setUserList(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  const selectHandler = async (e) => {
    const selectedWebinarId = e.value;
    const singleWebinar = werbibar_details.find((val) => val?._id == e.value);
    console.log(singleWebinar, "singleWebinarsingleWebinar");
    setSessions(singleWebinar?.sessions);
    let newWeb = !singleWebinar ? null : { value: singleWebinar?._id, label: singleWebinar?.title }
    setSelTab({ ...selTab, ...{ webinar: newWeb, session: null } })
    // setFormvalue({ ...formvalue, webinar_id: e.value,session_id: "" });
    setFormvalue((prevFormValue) => ({
      ...prevFormValue,
      webinar_id: selectedWebinarId,
      session_id: "" // Reset session_id since a new webinar was selected
    }));
  };

  const sessionselectHandler = async (e) => {
    console.log("session selected:", e.value);
    const selectedSessionId = e.value;

    const selectedSession = werbibar_details.reduce((acc, webinar) => {

      const session = webinar.sessions.find((session) => session._id === selectedSessionId);

      if (session) {
        acc = { ...session, webinar_id: webinar._id };
      }

      return acc;
    }, null);

    if (selectedSession) {
      setSelTab({
        ...selTab,
        session: {
          value: selectedSession.webinar_id,
          label: convertTime(selectedSession.startDate, selectedSession.timeZone)
        }
      });

      setFormvalue((prevFormValue) => ({
        ...prevFormValue,
        session_id: selectedSessionId
      }));
    } else {
      console.log("No session found for the selected ID.");
    }
  };

  const convertTime = (sessiondate, timezone) => {
    console.log(sessiondate, timezone);

    // const pacificMidwayDateTime = moment.tz(
    //   new Date(sessiondate),
    //   "MM/DD/YYYY hh:mm A",
    //   timezone
    //   sessiondate
    // );

    const localTime = moment(sessiondate);

    const pacificMidwayDateTime = localTime.tz(timezone);

    console.log(
      pacificMidwayDateTime.format("MM/DD/YYYY hh:mm A"),
      "istDateTime1"
    );

    return pacificMidwayDateTime.format("MM/DD/YYYY hh:mm A");
  };

  return (
    <>
      <div className="nav_sec">
        <Header />
      </div>
      <div className="dashboard-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 ps-0">
              <Innerpagenavbar />
            </div>
            <div className="col-lg-10">
              <div className="dashbord-right">
                <div className="dashbord-right-body-1 dashboard-padding analytics">
                  <div className="inner_home_shadow  ">
                    <div className="dashbord-right-header inner_home_dashboard ">
                      <p>My active registrants</p>
                    </div>
                    <div className="analytics_select dashbordrightbody-list1  mt-4">
                      <div className="select_box">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Webinar
                        </label>

                        <Select
                          id="filterSessions"
                          className="filterSessions"
                          aria-label="Default select example"
                          onChange={selectHandler}
                          value={webinar}
                          options={[
                            { value: 1, label: "Open this select menu" }, // Custom "Select Session" option
                            ...(Array.isArray(werbibar_details)
                              ? werbibar_details.map((val) => ({
                                value: val._id,
                                label: val.title,
                              }))
                              : []),
                          ]}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "3px",

                            colors: {
                              ...theme.colors,
                              primary25: "#dbdfe3",
                              primary: "#dbdfe3",
                              neutral50: "#696e70",
                              primary50: "#dbdfe3",
                            },
                          })}
                          placeholder="Open this select menu"
                        />

                        {/* <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={selectHandler}
                    >
                      <option selected>Open this select menu</option>

                      {werbibar_details.map((val) => (
                        <option value={val._id}>{val.title}</option>
                      ))}
                    </select> */}
                      </div>
                      <div className="select_box">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Session
                        </label>
                        <Select
                          id="filterSessions"
                          className="filterSessions"
                          aria-label="Default select example"
                          onChange={sessionselectHandler}
                          value={session}
                          options={[
                            { value: 1, label: "Select Session" }, // Custom "Select Session" option
                            ...(Array.isArray(sessions)
                              ? sessions.map((val) => ({
                                value: val._id,
                                label: convertTime(val.startDate, val.timeZone),
                              }))
                              : []), // Fallback to an empty array if sessions is not an array
                          ]}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "3px",

                            colors: {
                              ...theme.colors,
                              primary25: "#dbdfe3",
                              primary: "#dbdfe3",
                              neutral50: "#696e70",
                              primary50: "#dbdfe3",
                            },
                          })}
                          placeholder="Open this select menu"
                        />

                        {/* <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={sessionselectHandler}
                      // value={}
                    >
                      <option>Select Session</option>
                      {sessions?.length > 0 &&
                        sessions.map((val) => (
                          <option value={val._id}>
                            {convertTime(val.startDate, val.timeZone)}
                          </option>
                        ))}
                    </select> */}
                      </div>
                      <Button
                        className="primary_btn"
                        onClick={Submithandler}
                      >
                        Go
                      </Button>
                    </div>
                    <div className="inner_active_body">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>User name</th>
                              <th>Mail Id</th>
                              <th>Phone number</th>
                              <th>Joined at</th>
                              <th>Duration</th>
                            </tr>
                          </thead>
                          <tbody>
                            {userList.length > 0 ? (
                              <>
                                {userList.map((val) => (
                                  <tr>
                                    <td>{val?.username}</td>

                                    <td>{val?.mailid}</td>
                                    <td>{val?.phone}</td>
                                    <td>
                                      {moment(val?.registerdate).format("LLL")}
                                    </td>
                                    <td>{val?.Duration}</td>
                                  </tr>
                                ))}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={5} style={{ textAlign: "center" }}>
                                  No User Registers
                                </td>
                              </tr>
                            )}

                            {/* <tr>
                          <td>John</td>

                          <td>john@example.com</td>
                          <td>4 Jun 2024 12:04:56 AM</td>
                          <td>2 Hrs</td>
                        </tr>
                        <tr>
                          <td>John</td>

                          <td>john@example.com</td>
                          <td>4 Jun 2024 12:04:56 AM</td>
                          <td>2 Hrs</td>
                        </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>
      <Innerfooter />
    </>
  );
};
export default InnerActiveRegistration;
