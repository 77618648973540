

import React, { useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Header from "../components/Navbar";
import Footer from "../components/Footer";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/images/logo.svg";
import { Login_user, check_email } from "../action/user.action";
import { toastAlert } from "../utils/toast";
import { setCookies } from "../utils/cookies";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

const verifyform = {
    email: ''
}


function Forgotpassword() {

    const inputRef = useRef(null);
    const location = useLocation()
    const navigate = useNavigate()


    const [emailverify, setemailverify] = useState(verifyform)
    const [error, setError] = useState()
    const { email } = emailverify


    const clearInput = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    const changesHadler = async (e) => {
        try {
            const { id, value } = e.target
            setemailverify({ ...emailverify, [id]: value })
        } catch (error) {
            console.log(error)
        }
    }

    function ValidateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        }
        return false;
    }

    const Fromvalidation = async () => {
        try {
            var validateError = {};

            if (email.trim() == "") {
                validateError.email = "Email is required";
            } else if (!ValidateEmail(email)) {
                validateError.email = "Invalid email address";
            }
            setError(validateError);
            return validateError;
        } catch (err) {
            //console.log(err);
        }
    };

    const submit_Handler = async () => {
        try {
            const check = await Fromvalidation();
            var errorsSize = Object.keys(check).length;

            if (errorsSize == 0) {
                const { status, result } = await check_email(emailverify)
                if (status) {
                    clearInput()
                    toastAlert('success', result.message)

                    sessionStorage.setItem('FORGE_AUTH_TKN', result.encryptToken)
                } else {
                    setError(result.message)
                }
            }
        } catch (error) {
            toastAlert('error', "Something went wrong,please try again later")
        }
    }

    return (
        <>
            <div className="nav_sec">
                <Header />
            </div>

            <section className="login_page">
                <Container>
                    <a href="/">
                        <img
                            src={logo}
                            className="img-fluid brand_logo mx-auto d-block"
                            alt="logo"
                        />
                    </a>

                    <div className="login_content">
                        <h4 className="text-center">Forgot Password</h4>

                        <div class="mb-4 mt-4">
                            <label for="exampleFormControlInput1" class="form-label">
                                Email address
                            </label>
                            <input
                                type="email"
                                class="form-control"
                                id="email"
                                placeholder="Enter your email"
                                Value={email}
                                onChange={changesHadler}
                                ref={inputRef}
                            />
                            {
                                error && error.email ? <span style={{ color: 'red' }}>{error.email}</span> : ''
                            }
                        </div>

                        <button type="button" class="login_button mt-4" onClick={submit_Handler}>
                            Submit
                        </button>
                        <p className="mt-3 text-center">
                            Remembered your password? <Link to="/login">Log in here</Link>
                        </p>

                    </div>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default Forgotpassword;
