import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
//API
import { getRegistartionTemplate } from "../../action/user.action";
import config from "../../lib/config";

const TemplateView = () => {
  const { id, tid, meetingType } = useParams();

  //STATE
  const [templateValue, setTemplateValue] = useState(null);

  useEffect(() => {
    if (tid) {
      getTemplate(tid);
    }
  }, [tid]);

  const getTemplate = async (templateid) => {
    //PAYLOAD
    let payload = {
      templateId: templateid || "",
    };

    try {
      let { status, result } = await getRegistartionTemplate(payload);
      if (status) {
        setTemplateValue(result?.result?.content);
      }
    } catch (err) {
      console.log(err, "err");
    } finally {
    }
  };

  // //EXTRACT - CKEDITOR DATA
  useEffect(() => {
    if (templateValue) {
      //REMOVE CKEDITOR IN THIS CONTENT
      Array.from(document.querySelectorAll(".ck")).forEach((elem) => {
        elem.removeAttribute("lang");
        elem.removeAttribute("dir");
        elem.removeAttribute("role");
        elem.removeAttribute("aria-label");
        elem.removeAttribute("contenteditable");
      });
      //REMOVE TIMER - FOR INSTANT MEETING
      if (meetingType == 0) {
        let element = document.getElementById("normal-countdown");
        element?.parentNode?.removeChild(element);
      }
    }
  }, [templateValue]);

  return (
    <>
      {templateValue && (
        <div>
          <div className="temp_preview navbar_right">
            <Link className="navbar-brand" to="#">
              <img src={logo} className="img-fluid brand_logo" alt="logo" />
            </Link>
            <a
              href={`${config.front_URL}/configSettings/${id}`}
              className=" primary_btn"
            >
              Back
            </a>
          </div>
          {ReactHtmlParser(templateValue)}
        </div>
      )}
    </>
  );
};
export default TemplateView;
