import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";
import Home from "./pages/home.js";
import Login from "./pages/login.js";
import Register from "./pages/register.js";
import WebinarPopup1 from "./components/dashboard/Webinar-popup1.js";
import ProtectedRouter from "./router/productedRouter.js";
import LoginRouter from "./router/loginRouter.js";
import { ToastContainer } from "react-toastify";
import EmailVerify from "./pages/email_verify.js";
import Email_Verification from "./pages/email_verification.js";
import Forgotpassword from "./pages/forget_password.js";
import ResetPassword from "./pages/resetpassword.js";
import Settingpage from "./components/dashboard/settingpage.js";
import Webinarpage from "./pages/express/schudule/webinar.js";
import Template from "./pages/templates/template.js";
import Template_1 from "./pages/templates/template_1.js";
import Template_2 from "./pages/templates/template_2.js";
import Template_3 from "./pages/templates/template_3.js";
import Template_4 from "./pages/templates/template_4.js";
import Template_5 from "./pages/templates/template_5.js";
import Template_6 from "./pages/templates/template_6.js";

import WebinarpageEdit from "./pages/express/schudule/webinaredit.js";

import HelperRouter from "./helper.router.js";
import RegistrationPage from "./pages/registrationPage.js";
import RegistrationPageOne from "./pages/reminderNotification.js";
// import Settingpage from "./components/dashboard/settingpage.js";
// import Webinarpage from "./pages/webinar.js";
import WebinarAnalytics from "./pages/analytics.js";
import ActiveRegistrant from "./pages/activeRegistrant.js";
// import InstantMeeting from "./pages/instantMeeting.js";
import RegistrationPageTwo from "./pages/registrationPageTwo.js";
import BasicSettings from "./pages/webinar/basic.settings.js";
import WebinarSchedule from "./pages/webinar/webinar.schedule.js";
import RegistrationPageFrom from "./pages/webinar/register.page.js";
import Notification from "./pages/webinar/notification.js";
// import WebinarSchedule from "./pages/webinarSchedule.js";
import ConfigrationPage from "./pages/configration.js";
import UserRegister from "./pages/meeting/register.js";
import InstantMeeting from "./pages/instantMeeting.js";
import LastMinuteChecklist1 from "./pages/last-minute-checklist1.js";
import LastMinuteChecklist3 from "./pages/last-minute-checklist3.js";
import LastMinuteChecklist2 from "./pages/last-minute-checklist2.js";
import Innerfooter from "./components/inner-footer.js";
import CompletedMeeting from "./pages/meeting/completed_meeting.js";
import NewBasicSettings from "./pages/express/instance/basic.setting.js";
// import EditBasicSettings from "./pages/newWebinar/edit.basicSettings.js";
import MyWebinar from "./pages/myWebinar.js";
import UserLandingPage from "./pages/meeting/UserLandingPage.js";
import NewUser from "./pages/NewUser.js";
import RegistrationNotification from "./components/configration/RegistrationNotification.js";
import ConfirmMail from "../src/pages/confirmMail.js";
import Settings from "./pages/settings.js";

import { useDispatch } from "react-redux";
import { getAuthorizationHeader } from "./utils/cookies.js";
import { getUserDetails } from "./redux/features/userdetails.js";
import { Webinar_Details } from "./redux/features/webinar_details.js";
import Cookies from "js-cookie";
import FullConfigration from "./pages/fullconfigration.js";
import BasicFullconfig from "./components/fullconfigraton/basicsetting-fullconfig.js";
import RegistrationFullconfig from "./components/fullconfigraton/registration-fullconfig.js";
import Webinarschedulefullconfig from "./components/fullconfigraton/webinarschedule-fullconfig.js";

import ConfigSettings from "../src/pages/fullconfig/create/basicSettings.js";
import FinishMeeting from "./components/fullconfigraton/finishMeeting.js";
import Defaulttemplate from "./pages/templates/defaulttemplate.js";
import TemplateView from "./components/regtemplate/TemplateView.js";
// inner_navbar_home
import InnerHome from "./pages/inner_home.js";
import InnerSupportTicket from "./pages/inner_support_ticket.js";
import InnerSettings from "./pages/inner_settings.js";
import InnerActiveRegistration from "./pages/inner_active_registration.js";
import SupportTicket from "./pages/support_ticket.js";

//Canva Template
import CanvaTemplate from "./components/regtemplate/canvatemplate.js";

import CompletedMeetings from "./pages/CompletedMeetings.js";
import AllMeetings from "./pages/AllMeetings.js";

import Chart from "../src/components/dashboard/Chart.js";
import Subscription from "../src/pages/Subscription-plan.js";
const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
    // { path: "/dashboard", element: <Dashboard /> },
    { path: "/WebinarPopup1", element: <WebinarPopup1 /> },
    { path: "/registrationPage", element: <RegistrationPage /> },
    { path: "/registrationPageOne", element: <RegistrationPageOne /> },
    { path: "/analyticsPage", element: <WebinarAnalytics /> },
    { path: "/activeRegistrant", element: <ActiveRegistrant /> },
    { path: "/registrationPageTwo", element: <RegistrationPageTwo /> },
    { path: "/webinarschedule", element: <WebinarSchedule /> },
    { path: "/configration", element: <ConfigrationPage /> },

    { path: "/setting", element: <Settingpage /> },
    // { path: "/webinar", element: <Webinarpage /> },
    { path: "/InstantMeeting", element: <InstantMeeting /> },
    { path: "/landing-page", element: <UserLandingPage /> },

    // {path: "/WebinarPopup1", element: <WebinarPopup1 />}
  ]);
  return routes;
};

const AppWrapper = () => {
  const dispatch = useDispatch();

  async function checkToken() {
    const token = Cookies.get("user_token");
    if (token) {
      dispatch(Webinar_Details());
      dispatch(getUserDetails());
    } else {
      const token = await getAuthorizationHeader();
      console.log("saran");
      dispatch(Webinar_Details());
      dispatch(getUserDetails());
    }
  }

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRouter />}>
          {/* MyWebinar */}
          <Route path="/webinar" element={<MyWebinar />} />
          {/* Express */}
          {/* <Route path="/express/:id" element={<Webinarpage />} /> */}
          <Route path="/NewBasicSettings/:id" element={<NewBasicSettings />} />
          {/* <Route path="/instanceMeeting" element={<InstantMeeting />} /> */}
          <Route path="/WebinarPopup1" element={<WebinarPopup1 />} />
          <Route path="/dashboard" element={<Settingpage />} />
          <Route path="/express/:id" element={<Webinarpage />} />
          <Route path="/registrationPage" element={<RegistrationPage />} />
          <Route
            path="/RegistrationPageOne"
            element={<RegistrationPageOne />}
          />
          <Route path="/WebinarAnalytics" element={<WebinarAnalytics />} />
          <Route
            path="/WebinarAnalytics/:webinarid/:sessionid "
            element={<WebinarAnalytics />}
          />
          <Route path="/report" element={<CompletedMeetings />} />
          <Route path="/ActiveRegistrant" element={<ActiveRegistrant />} />
          <Route
            path="/RegistrationPageTwo"
            element={<RegistrationPageTwo />}
          />
          <Route path="/BasicSettings/:id" element={<BasicSettings />} />
          <Route path="/schedules/:id" element={<WebinarSchedule />} />
          <Route
            path="/RegistrationPage/:id"
            element={<RegistrationPageFrom />}
          />
          <Route path="/Notification/:id" element={<Notification />} />
          <Route path="/configration" element={<ConfigrationPage />} />
          <Route path="/express/:id" element={<Webinarpage />} />
          <Route path="/express-edit/:id" element={<WebinarpageEdit />} />
          <Route
            path="/configSettings/config/:token"
            element={<ConfigSettings />}
          />
          <Route path="/configSettings/:id" element={<ConfigSettings />} />
          <Route
            path="/last-minute/step1/:id"
            element={<LastMinuteChecklist1 />}
          />
          <Route
            path="/last-minute/step2/:id"
            element={<LastMinuteChecklist2 />}
          />
          <Route
            path="/last-minute/step3/:id"
            element={<LastMinuteChecklist3 />}
          />
          <Route path="/inner-home" element={<InnerHome />} />
          <Route
            path="/inner-support-ticket"
            element={<InnerSupportTicket />}
          />
          <Route path="/inner-settings" element={<InnerSettings />} />
          -----------------------------------------------------------------------------------------------------------
          {/* <Route path="/canva-template/:webinarId" element={<CanvaTemplate />} /> */}
          <Route
            path="/canva-template/:webinarId/:tid/:meetingType"
            element={<CanvaTemplate />}
          />
          <Route
            path="/canva-template/:webinarId/:meetingType"
            element={<CanvaTemplate />}
          />
          <Route path="/canva-template" element={<CanvaTemplate />} />
          -----------------------------------------------------------------------------------------------------------
          <Route path="/inner-active" element={<InnerActiveRegistration />} />
          <Route path="/allmeetings" element={<AllMeetings />} />
          <Route path="/support-ticket" element={<SupportTicket />} />
        </Route>

        <Route path="/" element={<Home />} />

        <Route path="/template" element={<Template />} />
        <Route
          path="/template1/:id/:tid/:meetingType"
          element={<Template_1 />}
        />
        <Route
          path="/template2/:id/:tid/:meetingType"
          element={<Template_2 />}
        />
        <Route
          path="/template3/:id/:tid/:meetingType"
          element={<Template_3 />}
        />
        <Route
          path="/template4/:id/:tid/:meetingType"
          element={<Template_4 />}
        />
        <Route
          path="/template5/:id/:tid/:meetingType"
          element={<Template_5 />}
        />
        <Route
          path="/template6/:id/:tid/:meetingType"
          element={<Template_6 />}
        />
        <Route
          path="/template-viewer/:id/:tid/:meetingType"
          element={<TemplateView />}
        />

        <Route path="/CompletedMeeting/:id" element={<CompletedMeeting />} />
        <Route path="/enroll/:id" element={<Defaulttemplate />} />

        <Route element={<LoginRouter />}>
          <Route path="/EmailVerify" element={<EmailVerify />} />
          <Route
            path="/Email_Verification/:id"
            element={<Email_Verification />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgetpassword" element={<Forgotpassword />} />
          <Route path="/reset_Password/:id" element={<ResetPassword />} />
          <Route path="/ConfirmMail" element={<ConfirmMail />} />
        </Route>

        {/* ----------------------------gaayathri---------------------------- */}

        <Route path="/NewUser" element={<NewUser />} />
        <Route
          path="/RegistrationNotification"
          element={<RegistrationNotification />}
        />
        <Route path="/innerfooter" element={<Innerfooter />} />
        {/* ----------------------------gaayathri---------------------------- */}
        <Route path="/landing-page/:token" element={<UserLandingPage />} />

        <Route path="/attendeesRegister/:id" element={<UserRegister />} />

        <Route path="/Settings" element={<Settings />} />
        <Route path="/Fullconfigration" element={<FullConfigration />} />
        <Route
          path="/RegistrationFullconfig"
          element={<Webinarschedulefullconfig />}
        />
        <Route path="/FinishMeeting" element={<FinishMeeting />} />
        {/* ----------------------------kaviya---------------------------- */}

        <Route path="/chart" element={<Chart />} />
        <Route path="/Subscription" element={<Subscription />} />
      </Routes>

      <ToastContainer />

      <HelperRouter />
    </Router>
  );
};

export default AppWrapper;
